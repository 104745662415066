import applicationApi from '../api';
import { CURRENCY } from './dto/buyer-seat-dto';

export interface PriceRangeReq {
  countries: string[];
  mediaTypes: string[];
  publisherIds: number[];
  currency: CURRENCY;
}

export interface PriceRangeDto {
  min: number;
  max: number;
}

export const PriceRangeService = {
  getFloorPrice: (data: PriceRangeReq): Promise<PriceRangeDto> => {
    return applicationApi.post('/adserver/floor_price/spread', data).then((res) => res.data);
  },
};
