import { CardClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomCard: Partial<OverridesStyleRules<CardClassKey>> | undefined = {
  root: {
    borderRadius: '2px',
    color: getColor('text'),

    '& .MuiCardContent-root': {
      padding: '32px 24px 24px',
    },

    '&.segment-card': {
      width: '228px',
      height: '208px',
      padding: '48px 24px',
      cursor: 'pointer',
    },
  },
};
