import styled from '@emotion/styled';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Slider from '@mui/material/Slider';
import { DEAL_STATUS, DEVICE_TYPE, MEDIA_TYPE } from '../../../../util/deals-util';
import { CountryDto } from '../../../../api/services/dto/country-dto';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import SitesDialog from './sites-dialog/SitesDialog';
import { applicationService } from '../../../../api/services/application.service';
import {
  PriceRangeReq,
  PriceRangeDto,
  PriceRangeService,
} from '../../../../api/services/price-range.service';
import { DealDto } from '../../../../api/services/dto/deal-dto';
import { SharedState, SubmitStep } from '../Create';
import { roundToTwoDecimals, twoNumbersMean } from '../../../../util/price-range-util';
import { useAlert } from '../../../shared/AlertProvider';
import { CURRENCY } from '../../../../api/services/dto/buyer-seat-dto';

const Summary = forwardRef<SubmitStep, SharedState>((props, ref) => {
  const { setAlert } = useAlert();

  const [sitesDialogOpen, setSitesDialogOpen] = useState(false);
  const [dealData, setDealData] = useState<DealDto | null>(null);
  const [countriesData, setCountriesData] = useState<CountryDto[]>([]);
  const [sitesData, setSitesData] = useState<any[]>([]);
  const [priceRangeData, setPriceRangeData] = useState<PriceRangeDto | null>(null);
  const dealId = props.dealId!;
  let publishDealRef: SubmitStep = (): Promise<void> => {
    const minFloorPrice = priceRangeData?.min;
    if (minFloorPrice && minFloorPrice > 0) {
      return publishDeal(dealId, minFloorPrice);
    } else {
      return Promise.reject(
        new Error("Can't publish without floorprice.", {
          cause: 'No floorprice is set for this deal - please contact buyer@cwire.com.',
        })
      );
    }
  };

  useImperativeHandle(ref, () => publishDealRef);
  const fetchDeal = async (id: number) => {
    const data: DealDto = await applicationService.getDeal(id);
    setDealData(data);
  };

  const fetchCountries = async (id: number) => {
    const data: CountryDto[] = await applicationService.getSelectedCountries(id);
    setCountriesData(data);
  };

  const fetchSites = async (id: number) => {
    const data: any[] = await applicationService.getSelectedSites(id);
    setSitesData(data);
  };

  const publishDeal = async (id: number, floorPrice: number) => {
    await applicationService.publishDeal(id, floorPrice);
  };
  const fetchFloorPrice = async (req: PriceRangeReq) => {
    const data: PriceRangeDto = await PriceRangeService.getFloorPrice(req);
    const doubleConversion = {
      min: data.min / 100000,
      max: data.max / 100000,
    };
    setPriceRangeData(doubleConversion);
  };

  useEffect(() => {
    fetchDeal(dealId).catch((reason) =>
      setAlert({
        open: true,
        severity: 'error',
        title: `Failed to fetch deal`,
        message: reason.message,
      })
    );
  }, [dealId]);

  useEffect(() => {
    fetchSites(dealId).catch((reason) =>
      setAlert({
        open: true,
        severity: 'error',
        title: 'Failed to fetch sites',
        message: reason.message,
      })
    );
  }, [dealId]);

  useEffect(() => {
    fetchCountries(dealId).catch((reason) =>
      setAlert({
        open: true,
        severity: 'error',
        title: 'Failed to fetch countries',
        message: reason.message,
      })
    );
  }, [dealId]);

  useEffect(() => {
    if (dealData && sitesData?.length > 0 && countriesData?.length > 0) {
      const mediaTypes: string[] = dealData.formats.map((format) => format.type);
      const countries: string[] = countriesData.map((country) => country.code);
      const publisherIds: number[] = sitesData.map((site) => site.publisherId);
      const currency: CURRENCY = dealData.currency;

      const dataRes: PriceRangeReq = {
        countries,
        mediaTypes,
        publisherIds,
        currency,
      };

      fetchFloorPrice(dataRes).catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch floor price',
          message: reason.message,
          action: {
            label: 'Retry',
            function: (dataRes) => fetchFloorPrice(dataRes),
          },
        })
      );
    }
  }, [dealData, sitesData, countriesData]);

  const marks = [
    {
      value: 0,
      label: `${dealData?.currency} ${roundToTwoDecimals(priceRangeData?.min)}`,
    },
    {
      value: 50,
      label: `average - ${dealData?.currency} ${roundToTwoDecimals(
        twoNumbersMean(priceRangeData?.min, priceRangeData?.max)
      )}`,
    },
    {
      value: 100,
      label: `${dealData?.currency} ${roundToTwoDecimals(priceRangeData?.max)}`,
    },
  ];

  const handleToggleSitesDialogOpen = (isOpen: boolean) => {
    setSitesDialogOpen(isOpen);
    if (sitesData.length === 0) {
      fetchSites(dealId).catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch sites',
          message: reason.message,
        })
      );
    }
  };

  return (
    <StepWrapper>
      <StepTitle>Summary</StepTitle>

      <CardsWrapper>
        <SummaryCard elevation={4} sx={{ marginBottom: '32px' }}>
          <CardContent>
            <Label>Name</Label>
            <Value>{dealData?.name}</Value>

            <Label>Buyer Seat</Label>
            <Value>{dealData?.buyerSeat}</Value>

            <Label>Status</Label>
            <Value>
              <Chip
                label={
                  dealData?.status === DEAL_STATUS.ACTIVE
                    ? 'Active'
                    : dealData?.status === DEAL_STATUS.DRAFT
                    ? 'Draft'
                    : dealData?.status === DEAL_STATUS.IN_CREATION
                    ? 'In creation'
                    : dealData?.status === DEAL_STATUS.ERROR
                    ? 'Error'
                    : ''
                }
                className={
                  dealData?.status === DEAL_STATUS.ACTIVE
                    ? 'deal-status-active'
                    : dealData?.status === DEAL_STATUS.DRAFT
                    ? 'deal-status-draft'
                    : dealData?.status === DEAL_STATUS.IN_CREATION
                    ? 'deal-status-in-creation'
                    : dealData?.status === DEAL_STATUS.ERROR
                    ? 'deal-status-error'
                    : ''
                }
              />
            </Value>

            <Label>Deal ID</Label>
            <Value>{dealData?.xandrDealId ?? '--'}</Value>

            <Label>SSP</Label>
            <Value>{dealData?.ssp}</Value>

            <Label>Media Type</Label>
            <Value>{dealData?.formats[0]?.type}</Value>

            <Label>Ad Formats</Label>
            <Value>
              <AdFormatChipsWrapper>
                {dealData?.formats.map((format: any) => {
                  return (
                    <Chip
                      key={format.id}
                      label={
                        format.type === MEDIA_TYPE.BANNER
                          ? `${format.width}x${format.height}`
                          : `${format.name}`
                      }
                      className="summary-chip"
                      variant="outlined"
                    />
                  );
                })}
              </AdFormatChipsWrapper>
            </Value>

            <Label>Target Country</Label>
            <Value>
              {countriesData.map((country) => {
                return (
                  <Chip
                    key={country.id}
                    label={country.name}
                    variant="outlined"
                    className="summary-chip"
                  />
                );
              })}
            </Value>

            <Label>Device</Label>
            <Value>
              <DevicesChipsWrapper>
                {dealData?.devices.map((device: DEVICE_TYPE) => {
                  return (
                    <Chip
                      key={device}
                      label={device.toLowerCase()}
                      variant="outlined"
                      className="summary-chip"
                    />
                  );
                })}
              </DevicesChipsWrapper>
            </Value>

            <Label>Language</Label>
            <Value>
              <Chip label={dealData?.language} variant="outlined" className="summary-chip" />
            </Value>

            <Label>Sites</Label>
            <Value>
              <Link href="#" underline="hover" onClick={() => handleToggleSitesDialogOpen(true)}>
                {dealData?.siteList.length} sites selected
              </Link>
            </Value>
          </CardContent>
        </SummaryCard>

        <PriceRangeCardAndButtonContainer>
          <PriceRangeCard elevation={4} sx={{ marginBottom: '20px' }}>
            <CardContent>
              <PriceRangeLabel>Floor Price Range</PriceRangeLabel>

              <SliderWrapper>
                <Slider
                  orientation="vertical"
                  defaultValue={[0, 100]}
                  valueLabelDisplay="off"
                  marks={marks}
                  disabled
                />
              </SliderWrapper>
            </CardContent>
          </PriceRangeCard>
        </PriceRangeCardAndButtonContainer>
      </CardsWrapper>

      <SitesDialog
        sites={sitesData}
        open={sitesDialogOpen}
        toggleOpen={handleToggleSitesDialogOpen}
      />
    </StepWrapper>
  );
});

export default Summary;

const StepWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 32px 8px 16px;
`;

const StepTitle = styled.h2`
  margin-bottom: 32px;
`;

const CardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SummaryCard = styled(Card)`
  width: 960px;
`;

const PriceRangeCardAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceRangeCard = styled(Card)`
  width: 224px;
`;

const Label = styled.h3`
  margin-bottom: 12px;
`;

const PriceRangeLabel = styled.h3`
  padding-bottom: 24px;
`;

const Value = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 8px;
  }
`;

const AdFormatChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 640px;
`;

const DevicesChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SliderWrapper = styled.div`
  height: 176px;
  margin: 10px 0;
`;
