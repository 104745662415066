import { DialogClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomDialog: Partial<OverridesStyleRules<DialogClassKey>> | undefined = {
  root: {
    '&.buyer-pro-dialog': {
      '& .MuiPaper-root': {
        borderRadius: '2px',
        color: getColor('text'),

        '& .MuiDialogTitle-root': {
          '& .MuiButtonBase-root': {
            '&.MuiIconButton-root': {
              color: '#5500B48F',
              position: 'absolute',
              right: '12px',
              top: '12px',
            },
          },
        },

        '& .MuiDialogContent-root': {
          marginBottom: '24px',
          padding: '0 24px',
        },
      },
    },

    '&.loader-dialog': {
      '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: 0,
        maxHeight: '100%',
      },
    },
  },
};
