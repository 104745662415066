import styled from '@emotion/styled';
import { getColor, getLighterColor } from '../../../theme/colors/colors';
import { useNavigate } from 'react-router-dom';
import { Logout } from '../../auth/Logout';
import { StyledLink } from '../../../util/styles-util';

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <MainSidebar>
      <TopLinks>
        <StyledLink onClick={() => navigate('/')}>Deals</StyledLink>
      </TopLinks>
      <Spacer />
      <BottomLinks>
        <Logout />

      </BottomLinks>
      
      <DocumentLink href="https://cwire.notion.site/d13f0d1ccc2d43269b6be01306663ff1?v=65eed28fb51d4952b6f79c45942d8753" target="_blank">Documentation</DocumentLink>
    </MainSidebar>
  );
};

export default Sidebar;

const MainSidebar = styled.aside`
  height: 100%;
  width: 204px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 56px 8px 25px;
  background-color: ${getLighterColor('primary', 7)};
  color: ${getColor('primaryContrast')};
`;

const TopLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BottomLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

export const DocumentLink = styled.a`
  width: 100%;
  padding: 4px 16px;
  cursor: pointer;
  text-decoration: none;
  color: white;

  &:hover {
    background-color: ${getLighterColor('primary', 14)};
    border-radius: 4px;
  }
`;