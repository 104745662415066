import { SliderClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomSlider: Partial<OverridesStyleRules<SliderClassKey>> | undefined = {
  root: {
    '&.Mui-disabled': {
      color: getColor('primary'),
    },

    '& .MuiSlider-thumb': {
      '&[data-index="0"]': {
        backgroundColor: '#9393FF',
      },
    },

    '& .MuiSlider-mark': {
      '&[data-index="1"]': {
        opacity: 0,
      },
    },

    '& .MuiSlider-rail': {
      backgroundImage: `linear-gradient(to bottom, ${getColor('primary')}, #9393FF)`,
      width: '6px',
    },

    '& .MuiSlider-track': {
      backgroundImage: `linear-gradient(to bottom, ${getColor('primary')}, #9393FF)`,
      border: 'none',
      width: '6px',
    },
  },
};
