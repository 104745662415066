import { ChangeEvent, MouseEvent } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { DEAL_STATUS, formatDate } from '../../../../util/deals-util';
import DealsTablePaginationActions from './DealsTablePaginationActions';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';
import { DealOverviewDto } from '../../../../api/services/dto/deal-overview-dto';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { applicationService } from '../../../../api/services/application.service';
import { useAlert } from '../../../shared/AlertProvider';

type DealsTableProps = {
  dealList: DealOverviewDto[];
  total: number;
  page: number;
  rowsPerPage: number;
  handlePageChange: (newPage: number) => void;
  handleRowsPerPageChange: (newRowsPerPage: number) => void;
};

const DealsTable = ({
  dealList,
  total,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
}: DealsTableProps) => {
  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const handleDuplicate = (id:number) => {
    applicationService.duplicateDeal(id).then((deal:any) => {
      console.log(`Duplicate successed ${deal.id}`)
      navigate(`../edit/${deal.id}`)
    })
    .catch((err) => {
      setAlert({
        open: true,
        severity: 'error',
        title: err.message,
        message: err.options?.cause
      })
    });
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 100 }}>SSP</TableCell>
            <TableCell style={{ width: 300 }}>Buyer Seat</TableCell>
            <TableCell style={{ width: 100 }}>Deal ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell style={{ width: 150 }}>Status</TableCell>
            <TableCell align="center" style={{ width: 110 }}>
              Created
            </TableCell>
            <TableCell align="right" style={{ width: 70 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dealList?.length ? (
            dealList.map((deal: DealOverviewDto) => (
              <TableRow key={deal.id} data-testid={`deal-${deal.id}`}>
                <TableCell style={{ width: 100 }}>{deal.ssp}</TableCell>
                <TableCell style={{ width: 300 }}>{deal.buyerSeat}</TableCell>
                <TableCell style={{ width: 100 }}>{deal.xandrDealId || '--'}</TableCell>
                <TableCell>{deal.name}</TableCell>
                <TableCell style={{ width: 150 }}>
                  {
                    <Chip
                      label={
                        deal.status === DEAL_STATUS.ACTIVE
                          ? 'Active'
                          : deal.status === DEAL_STATUS.DRAFT
                            ? 'Draft'
                          : deal.status === DEAL_STATUS.IN_CREATION
                          ? 'In creation'
                          : deal.status === DEAL_STATUS.ERROR
                          ? 'Error'
                          : ''
                      }
                      className={`table-chip ${
                        deal.status === DEAL_STATUS.ACTIVE
                          ? 'deal-status-active'
                          : deal?.status === DEAL_STATUS.DRAFT
                          ? 'deal-status-draft'
                          : deal.status === DEAL_STATUS.IN_CREATION
                          ? 'deal-status-in-creation'
                          : deal.status === DEAL_STATUS.ERROR
                          ? 'deal-status-error'
                          : ''
                      }`}
                    />
                  }
                </TableCell>
                <TableCell style={{ width: 110 }} align="center">
                  {formatDate(deal.createdDate)}
                </TableCell>
                <TableCell style={{ width: 110, display:"flex"}} align="left">

                  <IconButton
                    disableRipple
                    onClick={() => navigate(`edit/${deal.id}`)}
                    className="buyer-pro-action-button"
                    title="Edit"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disableRipple
                    onClick={() => handleDuplicate(deal.id)}
                    className="buyer-pro-action-button"
                    title="Copy"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className="no-matches">
              <TableCell colSpan={7}>
                <NoMatches>No matches</NoMatches>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {dealList.length ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                colSpan={7}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={(e: MouseEvent<HTMLButtonElement> | null, newPage: number) =>
                  handlePageChange(newPage)
                }
                onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleRowsPerPageChange(parseInt(e.target.value, 10))
                }
                ActionsComponent={DealsTablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default DealsTable;

const NoMatches = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;
