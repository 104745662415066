import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { applicationService } from '../../../api/services/application.service';
import Button from '@mui/material/Button';
import { getColor } from '../../../theme/colors/colors';
import DealsTableSearchInput from './table/DealsTableSearchInput';
import { DealOverviewDto } from '../../../api/services/dto/deal-overview-dto';
import DealsTable from './table/DealsTable';
import { GetDealsQueryParams } from '../../../util/deals-util';
import { useAlert } from '../../shared/AlertProvider';

const ROWS_PER_PAGE_INIT: number = 10;

const List = () => {
  const { setAlert } = useAlert();
  const [deals, setDeals] = useState<DealOverviewDto[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_INIT);

  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const fetch = (params: GetDealsQueryParams) =>
    applicationService
      .getDeals(params)
      .then((res) => {
        setDeals(res.resultList);
        setTotal(res.totalItems);
      })
      .catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch deals',
          message: reason.message,
        })
      );

  useEffect(() => {
    const queryParams: GetDealsQueryParams = {
      page,
      size: rowsPerPage,
      search: search || undefined,
      sort: 'auditMetadata.createdDate,desc',
    };
    let timer: NodeJS.Timeout | undefined;
    if (search) {
      // This would be nicer:
      // https://adityasaxena.hashnode.dev/how-to-create-a-custom-debounce-react-hook-using-useeffect
      timer = setTimeout(() => fetch(queryParams), 500);
    } else {
      fetch(queryParams);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [page, rowsPerPage, search]);

  const handleSearchStringChange = (searchStr: string) => {
    setSearch(searchStr);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <PageWrapper>
      <TitleAndButtonWrapper>
        <Title>Deal Overview</Title>

        <Button color="primary" variant="contained" size="large" onClick={() => navigate('/new')}>
          Create Deal
        </Button>
      </TitleAndButtonWrapper>

      <SearchWrapper>
        <DealsTableSearchInput
          search={search}
          onSearchStringChange={handleSearchStringChange}
          searchByColumns="deal id or name"
          parentComponent="buyer-pro-table"
        />
      </SearchWrapper>

      <DealsTable
        dealList={deals}
        total={total}
        page={page}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </PageWrapper>
  );
};

export default List;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
`;

const Title = styled.h1`
  color: ${getColor('text')};
  letter-spacing: 0.25px;
`;

const TitleAndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
`;
