import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Field, FormikErrors, useFormikContext } from 'formik';
import { ToggleButtonGroup } from 'formik-mui';
import ToggleButton from '@mui/material/ToggleButton';
import { FormHelperText, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { PublisherDto } from '../../../../../api/services/dto/publisher-dto';
import { SiteDto } from '../../../../../api/services/dto/site-dto';
import { StyledSiteSelection } from './InventoryCardSiteSelection.styles';

type InventoryCardSiteSelectionProps = {
  publishers: PublisherDto[];
  sites: SiteDto[];
  sitesToFilter: SiteDto[];
  selectedPublisher: PublisherDto | null;
  onPublisherSelect: (id: number) => void;
  searchString: string;
  onSearchStringChange: (searchString: string) => void;
  propSavedSites: number[];
  setCustomDirtyState: () => void;
  helperText: boolean;
};

const InventoryCardSiteSelection = ({
  publishers,
  sites,
  sitesToFilter,
  propSavedSites,
  selectedPublisher,
  onPublisherSelect,
  searchString,
  onSearchStringChange,
  setCustomDirtyState,
  helperText,
}: InventoryCardSiteSelectionProps) => {
  const { setFieldValue, validateField } = useFormikContext();

  // Populates the list on the left; this array is not filtered, we just set the property "selected" to true/false
  const [filteredSites, setFilteredSites] = useState<SiteDto[]>([]);

  // Populates the list on the right; this array is filtered
  const [selectedSites, setSelectedSites] = useState<SiteDto[]>([]);
  const [savedSites, setSavedSites] = useState<number[]>(propSavedSites);

  useEffect(() => {
    setSavedSites(propSavedSites);
  }, [propSavedSites]);

  useEffect(() => {
    setFieldValue(
      'siteList',
      selectedSites.filter((site: SiteDto) => site.selected)
    );
    validateField('siteList');
  }, [selectedSites]);

  useEffect(() => {
    const setInitialValues = async () => {
      if (sites.length && !savedSites.length) {
        // These sites are filtered on BE by selecting publisher or by entering search string
        setFilteredSites([...sites]);

        // These sites are filtered in FE by clicking on Select All / Deselect All / Clear All,
        // and on selecting / deselecting / deleting individual sites (in both left and right column)
        setSelectedSites([...sites]);
      } else if (sites.length && savedSites.length) {
        const savedFilteredSites: SiteDto[] = sites.map((site: SiteDto) => {
          if (savedSites.includes(site.id)) {
            return { ...site, selected: true };
          } else {
            return { ...site, selected: false };
          }
        });
        setFilteredSites([...savedFilteredSites]);

        setSelectedSites([...savedFilteredSites]);
      }
    };
    setInitialValues();
  }, [sites, savedSites]);

  useEffect(() => {
    if (sitesToFilter.length) {
      const sitesPopulatedWithSelected: SiteDto[] = sitesToFilter.map((site: SiteDto): SiteDto => {
        const filtered: SiteDto[] = selectedSites.filter((s: SiteDto) => s.id === site.id);
        if (filtered.length) {
          const found: SiteDto = filtered[0];
          return {
            ...site,
            selected: found.selected,
          };
        } else {
          return site;
        }
      });

      setFilteredSites([...sitesPopulatedWithSelected]);
    } else {
      setFilteredSites([]);
    }
  }, [sitesToFilter]);

  const handleSelectAllSites = async () => {
    const updatedFilteredSites: SiteDto[] = filteredSites.map(
      (site: SiteDto): SiteDto => ({ ...site, selected: true })
    );
    setFilteredSites(updatedFilteredSites);
    setCustomDirtyState();
    // Update all sites to the list on the right
    let updatedSelectedSites: SiteDto[];
    if (selectedPublisher && searchString) {
      // If we have selected publisher and search string
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.publisherId === selectedPublisher.id && site.name.includes(searchString)
            ? { ...site, selected: true }
            : site
      );
    } else if (selectedPublisher && !searchString) {
      // If we have selected publisher, but no search string
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.publisherId === selectedPublisher.id ? { ...site, selected: true } : site
      );
    } else if (!selectedPublisher && searchString) {
      // If there's no selected publisher, but we have search string
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.name.includes(searchString) ? { ...site, selected: true } : site
      );
    } else {
      // No selected publisher, no search string; we select all sites of all publishers
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto => ({ ...site, selected: true })
      );
    }
    setSelectedSites(updatedSelectedSites);
  };

  const handleDeselectAllSites = async () => {
    const updatedSites: SiteDto[] = filteredSites.map(
      (site: SiteDto): SiteDto => ({ ...site, selected: false })
    );
    setFilteredSites(updatedSites);
    setCustomDirtyState();

    // Update all sites from the list on the right
    let updatedSelectedSites: SiteDto[];
    if (selectedPublisher && searchString) {
      // If we have selected publisher and search string
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.publisherId === selectedPublisher.id && site.name.includes(searchString)
            ? { ...site, selected: false }
            : site
      );
    } else if (selectedPublisher && !searchString) {
      // If we have selected publisher, but no search string
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.publisherId === selectedPublisher.id ? { ...site, selected: false } : site
      );
    } else if (!selectedPublisher && searchString) {
      // If there's no selected publisher, but we have search string
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.name.includes(searchString) ? { ...site, selected: false } : site
      );
    } else {
      // No selected publisher, no search string; we deselect all sites of all publishers
      updatedSelectedSites = selectedSites.map(
        (site: SiteDto): SiteDto => ({ ...site, selected: false })
      );
    }
    setSelectedSites(updatedSelectedSites);
  };

  const syncSelectedSitesAfterToggleSelectionOfIndividualSite = async (
    updatedFilteredSites: SiteDto[],
    siteId: number
  ) => {
    const foundSite = selectedSites.find((site: SiteDto) => site.id === siteId);
    if (foundSite) {
      const updatedSelectedSites: SiteDto[] = selectedSites.map(
        (site: SiteDto): SiteDto =>
          site.id === siteId
            ? {
                ...site,
                selected: !site.selected,
              }
            : site
      );
      setSelectedSites(updatedSelectedSites);
    }
  };

  const handleToggleSelectionOfIndividualSite = (siteId: number) => {
    const updatedFilteredSites: SiteDto[] = filteredSites.map(
      (site: SiteDto): SiteDto =>
        site.id === siteId ? { ...site, selected: !site.selected } : site
    );
    setCustomDirtyState();
    setFilteredSites(updatedFilteredSites);

    syncSelectedSitesAfterToggleSelectionOfIndividualSite(updatedFilteredSites, siteId);
  };

  const handleClearAllSelectedSites = async () => {
    const updatedSelectedSites: SiteDto[] = selectedSites.map(
      (site: SiteDto): SiteDto => ({ ...site, selected: false })
    );
    setCustomDirtyState();
    setSelectedSites(updatedSelectedSites);

    // Deselect all sites in the list on the left (set their "selected" property to false)
    const updatedSites: SiteDto[] = filteredSites.map(
      (site: SiteDto): SiteDto => ({ ...site, selected: false })
    );
    setFilteredSites(updatedSites);
  };

  const handleClearIndividualSelectedSite = async (siteId: number) => {
    const updatedSelectedSites: SiteDto[] = selectedSites.map(
      (site: SiteDto): SiteDto => (site.id === siteId ? { ...site, selected: false } : site)
    );
    setCustomDirtyState();
    setSelectedSites(updatedSelectedSites);

    // Deselect the site in the list on the left (set it's "selected" property to false)
    const updatedFilteredSites: SiteDto[] = filteredSites.map(
      (site: SiteDto): SiteDto => (site.id === siteId ? { ...site, selected: false } : site)
    );
    setFilteredSites(updatedFilteredSites);
  };

  return (
    <StyledSiteSelection.SitesWrapper>
      <StyledSiteSelection.Column>
        <StyledSiteSelection.FirstRow>Site List</StyledSiteSelection.FirstRow>
        <StyledSiteSelection.Row>
          <TextField
            size="small"
            label="Filter by Publisher"
            id="deal-creation-filterByPublisher-select"
            className="buyer-pro-select publisher-select"
            select
            inputProps={{
              'data-testid': 'deal-creation-filterByPublisher-select',
            }}
            variant="outlined"
            value={selectedPublisher ? selectedPublisher.id : ''}
            onChange={(e) => {
              onPublisherSelect(+e.target.value);
            }}
            SelectProps={{
              MenuProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            <MenuItem key="selectOption" value="">
              <em>All</em>
            </MenuItem>
            {publishers.map((publisher: PublisherDto) => (
              <MenuItem key={publisher.id} value={publisher.id}>
                {publisher.name}
              </MenuItem>
            ))}
          </TextField>
        </StyledSiteSelection.Row>

        <StyledSiteSelection.Row>
          <TextField
            className="buyer-pro-search sites-search"
            id="deal-creation-site-search"
            placeholder="Search Sites"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disableRipple>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searchString}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchStringChange(e.target.value)}
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            margin="normal"
          />
        </StyledSiteSelection.Row>

        <StyledSiteSelection.Row>
          <StyledSiteSelection.SiteListAndButtonWrapper>
            <StyledSiteSelection.SelectionButtonsWrapper>
              <Button
                onClick={handleSelectAllSites}
                size="small"
                className="selection-toggle-button"
                disableRipple
              >
                Select all
              </Button>
              <StyledSiteSelection.Pipe>|</StyledSiteSelection.Pipe>
              <Button
                onClick={handleDeselectAllSites}
                size="small"
                className="selection-toggle-button"
                disableRipple
              >
                Deselect all
              </Button>
            </StyledSiteSelection.SelectionButtonsWrapper>
            <StyledSiteSelection.SiteList>
              {filteredSites.length ? (
                filteredSites.map((site: SiteDto) => {
                  return (
                    <StyledSiteSelection.SiteRow
                      onClick={() => handleToggleSelectionOfIndividualSite(site.id)}
                      key={site.id}
                    >
                      <span>{site.name}</span>
                      {site.selected ? <CheckCircleIcon /> : <CircleOutlined />}
                    </StyledSiteSelection.SiteRow>
                  );
                })
              ) : (
                <StyledSiteSelection.NoMatches>No matches</StyledSiteSelection.NoMatches>
              )}
            </StyledSiteSelection.SiteList>
          </StyledSiteSelection.SiteListAndButtonWrapper>
        </StyledSiteSelection.Row>
      </StyledSiteSelection.Column>
      <StyledSiteSelection.Column>
        <StyledSiteSelection.FirstRow>Selected</StyledSiteSelection.FirstRow>
        <StyledSiteSelection.Row>
          <StyledSiteSelection.SiteListAndButtonWrapper>
            <StyledSiteSelection.ClearButtonWrapper>
              <StyledSiteSelection.SelectedText>
                <span>{selectedSites.filter((site: SiteDto) => site.selected).length}</span>{' '}
                selected
              </StyledSiteSelection.SelectedText>
              <Button
                onClick={handleClearAllSelectedSites}
                size="small"
                className="selection-toggle-button"
                disableRipple
                disabled={!selectedSites.filter((site: SiteDto) => site.selected).length}
              >
                Clear all
              </Button>
            </StyledSiteSelection.ClearButtonWrapper>
            <StyledSiteSelection.SiteList>
              <Field
                component={ToggleButtonGroup}
                orientation="vertical"
                name="siteList"
                type="checkbox"
                className="site-list-toggle-button-group"
              >
                {selectedSites.map((site: SiteDto) =>
                  site.selected ? (
                    <ToggleButton
                      key={site.id}
                      value={site.id}
                      aria-label={site.name}
                      className="site-list-toggle-button"
                      disableRipple
                      onClick={() => handleClearIndividualSelectedSite(site.id)}
                    >
                      <StyledSiteSelection.SelectedSiteRow>
                        <span>{site.name}</span>
                        <CancelIcon />
                      </StyledSiteSelection.SelectedSiteRow>
                    </ToggleButton>
                  ) : null
                )}
                <FormHelperText error={helperText}>
                  {helperText && 'At least one site is required'}
                </FormHelperText>
              </Field>
            </StyledSiteSelection.SiteList>
          </StyledSiteSelection.SiteListAndButtonWrapper>
        </StyledSiteSelection.Row>
      </StyledSiteSelection.Column>
    </StyledSiteSelection.SitesWrapper>
  );
};

export default InventoryCardSiteSelection;
