import { CircularProgressClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const CustomCircularProgress:
  | Partial<OverridesStyleRules<CircularProgressClassKey>>
  | undefined = {
  root: {
    '&.spinner': {
      height: '120px !important',
      width: '120px !important',
    },
  },
};
