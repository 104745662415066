import { useEffect } from 'react';
import './App.scss';
import { getColor, setThemeColor } from './theme/colors/colors';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from './theme/mui-theme/mui-theme';
import { Link, Route, Routes } from 'react-router-dom';
import { Callback } from './components/Callback';
import { AuthenticationGuard } from './components/auth/AuthGuard';
import styled from '@emotion/styled';
import List from './components/deals/list/List';
import Create from './components/deals/create/Create';
import { getVar } from './theme/ui-variables/ui-variables';
import Header from './components/shared/header/Header';
import Sidebar from './components/shared/sidebar/Sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import { registerAxiosInterceptors } from './api/api';
import BuyerSeatForm from './components/buyer-seat/BuyerSeatForm';
import CreateSegment from './components/deals/create/create-segment/CreateSegment';
import AlertComponent from './components/shared/AlertComponent';
import { AlertProvider } from './components/shared/AlertProvider';
import Organization from './components/organization/Organization';
import ScrollToTop from './components/shared/ScrollToTop';

function App() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  if (!audience) {
    throw new Error(`Auth0 Audience is not defined: ${audience}`);
  }

  useEffect(() => {
    let refreshToken = () =>
      getAccessTokenSilently({
        cacheMode: 'on',
        authorizationParams: {
          scope: 'openid profile email offline_access',
          audience: audience,
        },
      }).catch((err) => {
        console.info(`Refresh token expired: ${err}`);
        console.info('Redirecting to login.');
        loginWithRedirect({ appState: { returnTo: window.location.pathname } }).then();
      });

    registerAxiosInterceptors(refreshToken);
  }, []);

  useEffect(() => {
    setThemeColor();
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <PageContainer>
        <Header />

        <SidebarAndMainWrapper>
          <Sidebar />

          <AlertProvider>

            <Main>
              <ScrollToTop>
                <Routes>
                  <Route path="/" element={<AuthenticationGuard component={List} />} />

                  <Route path="new" element={<AuthenticationGuard component={Create} />} />
                  <Route path="edit/:id" element={<AuthenticationGuard component={Create} />} />

                  <Route path="buyer-seat" element={<AuthenticationGuard component={BuyerSeatForm} />} />
                  <Route path="organization" element={<AuthenticationGuard component={Organization} />} />
                  <Route path="segment" element={<AuthenticationGuard component={CreateSegment} />} />

                  <Route path="/callback" element={<Callback />} />

                  <Route path="*" element={<NoMatch />} />
                </Routes>

                <AlertComponent />
              </ScrollToTop>
            </Main>

          </AlertProvider>

        </SidebarAndMainWrapper>
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;

// Todo: Implement a proper 404 component
const NoMatch = () => {
  return (
    <div>
      <h2>404 Not Found</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SidebarAndMainWrapper = styled.div`
  display: flex;
  height: calc(100% - ${getVar('headerHeight')});
  overflow: hidden;
`;

const Main = styled.main`
  width: calc(100% - ${getVar('sidebarWidth')});
  height: 100%;
  padding: ${getVar('mainPadding')};
  background-color: ${getColor('secondary')};
  overflow-y: auto;
`;
