import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import styled from '@emotion/styled';
import { FieldHookConfig } from 'formik/dist/Field';

const NameCard = (props: FieldHookConfig<string>) => {
  const [field, meta, helper] = useField(props);

  return (
    <Card elevation={4} sx={{ marginBottom: '32px' }}>
      <CardContent>
        <CardTitle>Segment Name</CardTitle>

        <Wrapper>
          <TextField
            {...field}
            type="text"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && !!meta.error ? meta.error : undefined}
            className="buyer-pro-input"
          />
        </Wrapper>
      </CardContent>
    </Card>
  );
};

export default NameCard;

const CardTitle = styled.h3`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
