import { ChipClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import {getColor, getDarkerColor, getLighterColor} from '../../colors/colors';

export const CustomChip: Partial<OverridesStyleRules<ChipClassKey>> | undefined = {
  root: {
    '&.deal-status-draft': {
      backgroundColor: getLighterColor('secondaryContrast', 0, 0.3),
      color: getDarkerColor('secondaryContrast', 0, 0.9),
    },
    '&.deal-status-in-creation': {
      backgroundColor: getColor('dealStatusInCreation'),
      color: getColor('dealStatusInCreationContrast'),
    },
    '&.deal-status-active': {
      backgroundColor: getColor('dealStatusActive'),
      color: getColor('dealStatusActiveContrast'),
    },
    '&.deal-status-error': {
      backgroundColor: getColor('error'),
      color: getColor('errorContrast'),
    },

    '&.summary-chip': {
      '& .MuiChip-label': {
        textTransform: 'capitalize',
        color: getColor('text'),
        userSelect: 'none',
      },
    },
    '&.keyword-chip': {
      color: getColor('text'),
      backgroundColor: 'rgba(85, 0, 180, 0.08)', // primary color (#5500B4)
      margin: '0px 5px'
    },
  },
};
