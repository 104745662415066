import { CountryDto } from '../api/services/dto/country-dto';
import * as Yup from 'yup';
import { AdFormat } from '../api/services/dto/ad-format';
import { SiteDto } from '../api/services/dto/site-dto';

export const formatDate = (isoDate: string | undefined): string => {
  let formattedDate = '--';

  if (isoDate) {
    const dateObj = new Date(isoDate);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Pad single-digit day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    formattedDate = `${formattedDay}.${formattedMonth}.${year}`;
  }

  return formattedDate;
};

export interface GetDealsQueryParams {
  page: number;
  size: number;
  search?: string;
  sort?: string;
}

export interface SelectOption {
  value: string | number;
  label: string;
}

export enum DEAL_STATUS {
  DRAFT = 'DRAFT',
  IN_CREATION = 'IN_CREATION',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
}

export enum MEDIA_TYPE {
  BANNER = 'BANNER',
  NATIVE = 'NATIVE',
}

export enum LANGUAGE {
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  EN = 'EN',
}

export enum DEVICE_TYPE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}

export enum BRAND_SAFETY_LEVEL {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export interface DealCreateFormData {
  name: string;
  buyerSeat: string;
  mediaType: MEDIA_TYPE;
  adFormats: number[];
  targetCountries: CountryDto[];
  deviceTypes: DEVICE_TYPE[];
  language: LANGUAGE;
  siteList: SiteDto[];
  brandSafetyLevel: BRAND_SAFETY_LEVEL;
  description: string;
}

export const getDealCreateFormInitValues = (): DealCreateFormData => {
  return {
    name: '',
    buyerSeat: '',
    mediaType: MEDIA_TYPE.BANNER,
    adFormats: [],
    targetCountries: [],
    deviceTypes: [DEVICE_TYPE.DESKTOP],
    language: LANGUAGE.DE,
    siteList: [],
    brandSafetyLevel: BRAND_SAFETY_LEVEL.LOW,
    description: '',
  };
};

export const getDealCreateFormValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().trim().required(),
    buyerSeat: Yup.string().required(),
    mediaType: Yup.string().trim().required(),
    targetCountries: Yup.array().min(1).required(),
    adFormats: Yup.array().min(1).required(),
    deviceTypes: Yup.array().min(1).required(),
    language: Yup.string().trim().required(),
    siteList: Yup.array().min(1).required(),
    brandSafetyLevel: Yup.string().trim().required(),
  });
};

export const filterByMediaType = (adFormats: AdFormat[], mediaType: MEDIA_TYPE) => {
  return adFormats.filter((format: AdFormat) => format.mediaType === mediaType);
};

export interface GetSitesParams {
  languages: string;
  publisher_ids: string;
  name: string;
}

export interface GetPublishersParams {
  languages: string;
}
