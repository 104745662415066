import { TableClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomTable: Partial<OverridesStyleRules<TableClassKey>> | undefined = {
  root: {
    marginBottom: '56px',

    '& .MuiTableCell-root': {
      color: getColor('text'),
      padding: '6px 10px',
    },

    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          fontWeight: 600,
        },
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {},

        '&.no-matches': {
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
    },
    '& .MuiTableFooter-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          borderBottom: 'none',
        },
      },
    },
  },
};
