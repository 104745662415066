import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Field, useField } from 'formik';
import styled from '@emotion/styled';
import { FieldHookConfig } from 'formik/dist/Field';
import Chip from '@mui/material/Chip';
import * as React from 'react';
import { KeyboardEvent, useRef } from 'react';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

const NameCard = (props: { setCustomDirtyState: () => void } & FieldHookConfig<string[]>) => {
  const [field, meta, helper] = useField(props);
  let textField = useRef<BaseTextFieldProps>(null);
  const addKeyword = (keyword: string) => {
    // TODO distinct slice instead?
    if (!field.value.includes(keyword)) {
      helper.setValue([...field.value, keyword], true).then();
      if (textField.current) {
        textField.current.value = '';
      }
    }
  };
  const removeKeyword = (keyword: string) => {
    props.setCustomDirtyState();
    helper
      .setValue(
        field.value.filter((kw) => kw !== keyword),
        true
      )
      .then();
  };

  return (
    <Wrapper>
      <FieldTitle>Keywords</FieldTitle>
      <TextField
        className="buyer-pro-input wider"
        placeholder="Optional"
        variant="outlined"
        inputRef={textField}
        onKeyDown={(ev: KeyboardEvent<HTMLInputElement>) => {
          if (ev.key === 'Enter') {
            props.setCustomDirtyState();
            // @ts-ignore
            addKeyword(ev.target.value);
            ev.preventDefault();
          }
        }}
      />
      <KeywordFrame>
        <Keywords keywords={field.value} removeKeyword={removeKeyword} />
      </KeywordFrame>
      <Field {...field} type="hidden" />
    </Wrapper>
  );
};

const Keywords = (props: { keywords: string[]; removeKeyword: (keyword: string) => void }) => {
  return (
    <div>
      {props.keywords.map((keyword) => (
        <Chip
          className={'keyword-chip'}
          key={keyword}
          label={keyword}
          onDelete={() => props.removeKeyword(keyword)}
        ></Chip>
      ))}
    </div>
  );
};

export default NameCard;

const FieldTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 725px;
`;

const KeywordFrame = styled.div`
  padding: 24px 0px;
  min-height: 110px;
  gap: 19px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(30, 0, 50, 0.23);
`;
