import styled from '@emotion/styled';
import React, {  useState } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import OrganizationForm from './create-organization/OrganizationForm';
import AssignUserToOrganizationForm from './assign-user-to-organization/AssignUserToOrganizationForm';
import InviteUserToOrganizationForm from './invite-user-to-tenant/InviteUserToOrganizationForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Organization = () => {

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (<PageWrapper>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="organization-managment">
        <Tab label="Create an organization" {...a11yProps(0)} />
        <Tab label="Assign a user to an organization" {...a11yProps(1)} />
        <Tab label="Invite a user to an organization" {...a11yProps(2)} />

      </Tabs>
    </Box>
  <CustomTabPanel value={value} index={0}>
    <OrganizationForm/>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={1}>
    <AssignUserToOrganizationForm/>
  </CustomTabPanel>
    <CustomTabPanel index={2} value={value}>
      <InviteUserToOrganizationForm/>
    </CustomTabPanel>


  </PageWrapper>)
}

export default Organization;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
`;