import axios from 'axios';

const applicationApi = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
});

// https://stackoverflow.com/questions/69011315/how-to-get-auth0-token-from-outside-react-components
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const registerAxiosInterceptors = (refreshToken: () => Promise<string | void>) => {

  // Register request interceptor
  applicationApi.interceptors.request.use(async (config) => {
    let token = await refreshToken()
    console.debug(`token: ${token}`)
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

};
export default applicationApi;
