import { OrganizationDto } from './dto/user-managment-dto/organization-dto';
import { WarrantDto } from './dto/user-managment-dto/warrant-dto';
import { OrganizationRequestDto } from './dto/user-managment-dto/organization-request-dto';
import { UserDto } from './dto/user-managment-dto/user-dto';
import applicationApi from '../api';

const createOrganization = async (body: OrganizationRequestDto): Promise<OrganizationDto> => {
  const response = await applicationApi.post('/warrants/organizations', body);
  return response.data;
};

const assignUserToOrganization = async (body: WarrantDto): Promise<WarrantDto> => {
  const response = await applicationApi.put(`/warrants/organizations/${body.organizationId}/users`, { userId:body.userId });
  return response.data;
};

const inviteUsersToOrganization = async (organizationId: string, emails: string[]): Promise<void> => {
  await applicationApi.put(`/warrants/organizations/${organizationId}/invites`, {emails});
};

const fetchUsers =  async (): Promise<UserDto[]> => {
  const response =  await applicationApi.get('/warrants/users');
  return response.data;
}

const fetchOrganizations =  async (): Promise<OrganizationDto[]> => {
  const response =  await applicationApi.get('/warrants/organizations');
  return response.data;
}

export const warrantService = {
  createOrganization,
  assignUserToOrganization,
  fetchUsers,
  fetchOrganizations,
  inviteUsersToOrganization,
};