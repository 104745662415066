import { createTheme } from '@mui/material/styles';
import { getColor, getDarkerColor, getLighterColor } from '../colors/colors';
import { CustomDialog } from './overrides/mui-dialog';
import { CustomTextField } from './overrides/mui-text-field';
import { CustomButton, CustomIconButton } from './overrides/mui-button';
import { CustomCheckbox } from './overrides/mui-checkbox';
import { CustomTable } from './overrides/mui-table';
import { CustomChip } from './overrides/mui-chip';
import { CustomAutocomplete } from './overrides/mui-autocomplete';
import { CustomCircularProgress } from './overrides/mui-circular-progress';
import { CustomToggleButton, CustomToggleButtonGroup } from './overrides/mui-toggle-button';
import { CustomCard } from './overrides/mui-card';
import { CustomSlider } from './overrides/mui-slider';

export const muiTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: getLighterColor('primary', 10),
      main: getColor('primary'),
      dark: getDarkerColor('primary', 10),
      contrastText: getColor('primaryContrast'),
    },
    secondary: {
      light: getLighterColor('secondary', 10),
      main: getColor('secondary'),
      dark: getDarkerColor('secondary', 10),
      contrastText: getColor('secondaryContrast'),
    },
    error: {
      light: getLighterColor('error', 10),
      main: getColor('error'),
      dark: getDarkerColor('error', 10),
      contrastText: getColor('errorContrast'),
    },
    success: {
      light: getLighterColor('success', 10),
      main: getColor('success'),
      dark: getDarkerColor('success', 10),
      contrastText: getColor('successContrast'),
    },
    warning: {
      light: getLighterColor('warning', 10),
      main: getColor('warning'),
      dark: getDarkerColor('warning', 10),
      contrastText: getColor('warningContrast'),
    },
    info: {
      light: getLighterColor('info', 10),
      main: getColor('info'),
      dark: getDarkerColor('info', 10),
      contrastText: getColor('infoContrast'),
    },
  },
  typography: {
    fontFamily: ['Poppins', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    htmlFontSize: 16,
  },
  spacing: 5,
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: CustomButton,
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: CustomTextField,
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenu: {
      defaultProps: {
        variant: 'menu',
      },
    },
    MuiDialog: {
      styleOverrides: CustomDialog,
    },
    MuiCheckbox: {
      styleOverrides: CustomCheckbox,
    },
    MuiTable: {
      styleOverrides: CustomTable,
    },
    MuiChip: {
      styleOverrides: CustomChip,
    },
    MuiAutocomplete: {
      styleOverrides: CustomAutocomplete,
    },
    MuiCircularProgress: {
      styleOverrides: CustomCircularProgress,
    },
    MuiIconButton: {
      styleOverrides: CustomIconButton,
    },
    MuiToggleButton: {
      styleOverrides: CustomToggleButton,
    },
    MuiToggleButtonGroup: {
      styleOverrides: CustomToggleButtonGroup,
    },
    MuiCard: {
      styleOverrides: CustomCard,
    },
    MuiSlider: {
      styleOverrides: CustomSlider,
    },
  },
});
