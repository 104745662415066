import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { getColor } from '../../../../../theme/colors/colors';
import { SiteDto } from '../../../../../api/services/dto/site-dto';

type SitesDialogProps = {
  sites: SiteDto[];
  open: boolean;
  toggleOpen: (isOpen: boolean) => void;
};

const SitesDialog = ({ open, toggleOpen, sites }: SitesDialogProps) => {
  return (
    <Dialog
      className="buyer-pro-dialog"
      open={open}
      onClose={() => toggleOpen(false)}
      scroll="paper"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Selected Sites
        <IconButton onClick={() => toggleOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {sites?.map((site: SiteDto) => <SiteRow key={site.id}>{site.name}</SiteRow>)}
      </DialogContent>
    </Dialog>
  );
};

export default SitesDialog;

const SiteRow = styled.div`
  font-size: 13px;
  line-height: 1;
  color: ${getColor('text')};
  padding: 8px 8px 2px;
  border-bottom: 1px solid rgba(30, 0, 50, 0.12); // text color (#1E0032)
`;
