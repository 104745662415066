import { ToggleButtonClassKey } from '@mui/material';
import { ToggleButtonGroupClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomToggleButton: Partial<OverridesStyleRules<ToggleButtonClassKey>> | undefined = {
  root: {
    padding: '8px 7px 7.25px', // so that the height of the button is exactly 40px
    border: '1px solid rgba(0, 0, 0, 0.23)', // to match the border of the search input

    '&.MuiToggleButton-root': {
      color: getColor('text'),

      '&.Mui-selected': {
        backgroundColor: 'rgba(85, 0, 180, 0.08)', // primary color (#5500B4) in rgb
      },

      '&:hover': {
        backgroundColor: 'rgba(85, 0, 180, 0.08)', // primary color (#5500B4) in rgb
      },

      '&.ad-formats-toggle-button': {
        border: '1px solid rgba(0, 0, 0, 0.23) !important',
        borderRadius: '2px !important',
        margin: '0 !important',
        width: '102px',
        height: '82px',
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        textTransform: 'capitalize',
        lineHeight: '1.2',

        '& .MuiSvgIcon-root': {
          fill: 'rgba(85, 0, 180, .56)', // primary color (#5500B4)
        },
      },

      '&.site-list-toggle-button': {
        border: 'none',
        borderRadius: 0,
        padding: 0,
        textTransform: 'none',
        margin: '0 !important',
        width: '100%',
        fontSize: '13px',

        '& .MuiSvgIcon-root': {
          fill: 'rgba(85, 0, 180, .56)', // primary color (#5500B4)
        },

        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};

export const CustomToggleButtonGroup:
  | Partial<OverridesStyleRules<ToggleButtonGroupClassKey>>
  | undefined = {
  root: {
    '&.ad-formats-toggle-button-group': {
      width: '750px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px 6px',
      paddingBottom: '14px',

      '&.banner': {
        gap: '6px',
        paddingBottom: 0,
      },
    },

    '&.site-list-toggle-button-group': {
      width: '100%',
    },
  },
};
