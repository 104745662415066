import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { Field, useField } from 'formik';
import { AutocompleteRenderInputParams, ToggleButtonGroup, Autocomplete, fieldToSelect } from 'formik-mui';
import ToggleButton from '@mui/material/ToggleButton';
import { DEVICE_TYPE, LANGUAGE } from '../../../../../util/deals-util';
import { CountryDto } from '../../../../../api/services/dto/country-dto';
import { SyntheticEvent, useEffect, useState } from 'react';
import { FieldHookConfig } from 'formik/dist/Field';

type TargetingCardProps = {
  selected: number[];
  countries: CountryDto[];
  onLanguageChange: (newLanguage: LANGUAGE) => void;
  setCustomDirtyState: () => void;
};

const TargetingCard = (
  props: {
    selected: number[];
    countries: CountryDto[];
    onLanguageChange: (newLanguage: LANGUAGE) => void;
    setCustomDirtyState: () => void;
    touchedTargetCountries: any;
    errorTargetCountries: any;
  } & FieldHookConfig<CountryDto[]>
) => {

  const [selected, setSelected] = useState<number[]>(props.selected);
  const [countries, setCountries] = useState<CountryDto[]>(props.countries);
  const [field, _, helper] = useField(props);

  useEffect(() => {
    setSelected(props.selected);
    setCountries(props.countries);
  }, [props]);

  useEffect(() => {
    // reactive dependencies ftw.
    if (countries.length === 0 || selected.length === 0) {
      return;
    }
    let mapping = new Map(countries.map((country) => [country.id, country]));
    // Set the already selected values
    console.log(`Selected countries: ${selected}`);
    helper
      .setValue(
        selected.map((id) => mapping.get(id)!),
        true
      )
      .then();
  }, [countries, selected]);

  return (
    <Card elevation={4} sx={{ marginBottom: '32px' }}>
      <CardContent>
        <CardTitle>Targeting</CardTitle>

        <ComponentsWrapper>
          <Field
            className="buyer-pro-autocomplete category-autocomplete"

            name="targetCountries"
            id="targetCountries"
            multiple
            component={Autocomplete}
            options={countries}
            getOptionLabel={(country: CountryDto) => country.name}
            onChange={(_: SyntheticEvent, newValue:  CountryDto[]) => {
              props.setCustomDirtyState();
              return helper.setValue(newValue, true).catch();
            }}

            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField error={props.touchedTargetCountries && !!props.errorTargetCountries }
                         helperText={props.touchedTargetCountries && !!props.errorTargetCountries? "At least one target country is required": undefined}
                         label={'Target Countries'} variant="outlined" {...params}  />
            )}
          />

          <>
            <FieldLabel>Device Type</FieldLabel>
            <Field
              onClick={() => props.setCustomDirtyState()}
              component={ToggleButtonGroup}
              size="small"
              name="deviceTypes"
              type="checkbox"
            >
              <ToggleButton value={DEVICE_TYPE.DESKTOP} aria-label="desktop">
                DESKTOP
              </ToggleButton>
              <ToggleButton value={DEVICE_TYPE.MOBILE} aria-label="mobile">
                MOBILE
              </ToggleButton>
              <ToggleButton value={DEVICE_TYPE.TABLET} aria-label="tablet">
                TABLET
              </ToggleButton>
            </Field>
          </>

          <>
            <FieldLabel>Language</FieldLabel>
            <Field
              component={ToggleButtonGroup}
              exclusive
              size="small"
              name="language"
              type="checkbox"
              onClick={() => props.setCustomDirtyState()}
            >
              <ToggleButton
                value={LANGUAGE.DE}
                aria-label="de"
                onClick={() => props.onLanguageChange(LANGUAGE.DE)}
              >
                DE
              </ToggleButton>
              <ToggleButton
                value={LANGUAGE.FR}
                aria-label="fr"
                onClick={() => props.onLanguageChange(LANGUAGE.FR)}
              >
                FR
              </ToggleButton>
              <ToggleButton
                value={LANGUAGE.IT}
                aria-label="it"
                onClick={() => props.onLanguageChange(LANGUAGE.IT)}
              >
                IT
              </ToggleButton>

            </Field>
          </>
        </ComponentsWrapper>
      </CardContent>
    </Card>
  );
};

export default TargetingCard;

const CardTitle = styled.h3`
  margin-bottom: 16px;
`;

const ComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FieldLabel = styled.label`
  margin: 16px 0 8px;
`;
