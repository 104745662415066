import { SyntheticEvent, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Field, useField } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import TextField from '@mui/material/TextField';
import { IabTaxonomyService, TaxonomyCategory } from '../../../../../api/services/iab.service';
import styled from '@emotion/styled';
import { FieldHookConfig } from 'formik/dist/Field';

export const IncludesCard = (
  props: { selected: string[]; setCustomDirtyState: () => void } & FieldHookConfig<
    TaxonomyCategory[]
  >
) => {
  const [taxonomy, setTaxonomy] = useState<TaxonomyCategory[]>([]);
  const [selected, setSelected] = useState<string[]>(props.selected);
  const [field, _, helper] = useField(props);

  /**
   * Fetch the audience taxonomy
   */
  useEffect(() => {
    IabTaxonomyService.fetchAudience().then((categories) => {
      // used for options in autocomplete component
      setTaxonomy(categories);
    });
  }, []);

  // Update state when props change. TODO: Can this be done better?
  useEffect(() => {
    setSelected(props.selected);
  }, [props]);

  /**
   * Used for setting the selected values from props
   * This would not be needed it the AutoComplete component would be using the ids instead of objects:
   * https://github.com/mui/material-ui/issues/23708
   * https://github.com/mui/material-ui/issues/37287
   */
  useEffect(() => {
    // reactive dependencies ftw.
    if (taxonomy.length === 0 || selected.length === 0) {
      return;
    }
    let mapping = new Map(taxonomy.map((cat) => [cat.id, cat]));
    // Set the already selected values
    console.log(`Selected categories: ${selected}`);
    helper
      .setValue(
        selected.map((id) => mapping.get(id)!),
        true
      )
      .then();
  }, [taxonomy, selected]);

  return (
    <Wrapper>
      <Field
        className="buyer-pro-autocomplete category-autocomplete"
        {...field}
        multiple
        component={Autocomplete}
        options={taxonomy}
        getOptionLabel={(category: TaxonomyCategory) => category.name}
        onChange={(_: SyntheticEvent, newValue: TaxonomyCategory[]) => {
          props.setCustomDirtyState();
          return helper.setValue(newValue, false).catch();
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={'Content categories'}
            placeholder="Optional"
            variant="outlined"
            {...params}
          />
        )}
      />
    </Wrapper>
  );
};

const CardTitle = styled.h3`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
