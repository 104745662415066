import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useAlert } from '../../shared/AlertProvider';
import { warrantService } from '../../../api/services/warrant.service';
import { OrganizationRequestDto } from '../../../api/services/dto/user-managment-dto/organization-request-dto';

const regex = new RegExp('^[a-z_-]*$');
export const hasOnlyChars = (str: string) => regex.test(str);

export enum OrganizationErrorCodes {
  ORG_ID_REQUIRED = 'ORG_ID_REQUIRED',
  ORG_ID_INVALID_CHARS = 'ORG_ID_INVALID_CHARS',
  ORG_NAME_REQUIRED = 'ORG_NAME_REQUIRED',
}

export const getOrganizationErrorMessage = (code: OrganizationErrorCodes) => {
  switch (code) {
    case OrganizationErrorCodes.ORG_ID_REQUIRED:
      return 'Organization ID is required';
    case OrganizationErrorCodes.ORG_ID_INVALID_CHARS:
      return 'Organization ID can contain only lowercase, \'_\' and  \'-\' characters!';
    case OrganizationErrorCodes.ORG_NAME_REQUIRED:
      return 'Organization name is required';
    default:
      return 'Invalid input';
  }

};

export const validate = (values: OrganizationRequestDto): FormikErrors<OrganizationRequestDto> => {
  const errors: FormikErrors<OrganizationRequestDto> = {};
  console.log(`Validate: ${JSON.stringify(values)}`);
  if (!values.id) {
    errors.id = OrganizationErrorCodes.ORG_ID_REQUIRED;
  }

  if (!hasOnlyChars(values.id)) {
    errors.id = OrganizationErrorCodes.ORG_ID_INVALID_CHARS;
  }

  if (!values.name) {
    errors.name = OrganizationErrorCodes.ORG_NAME_REQUIRED;
  }

  return errors;
};
const OrganizationForm = () => {
  const { setAlert } = useAlert();
  const [org, setOrg] = useState<OrganizationRequestDto>({
    id: '',
    name: '',
  });

  useEffect(() => {
    console.log(org);
  }, [org]);

  const createOrganization = async (value: OrganizationRequestDto) => {
    await warrantService
      .createOrganization(value)
      .then(() => {
        setAlert({ open: true, title: 'Created the organization', severity: 'success' });
      })
      .catch((reason) => {
        console.error(`Failed to create organization: ${reason}`);
        setAlert({
          open: true,
          title: 'Failed to create organization',
          message: reason.message,
          severity: 'error' });
      });
    setOrg({
      id: '',
      name: '',
    });
  };

  return (
    <Formik
      initialValues={org}
      onSubmit={createOrganization}
      validate={validate}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ errors, touched, isValid }) => (
        <Form id='create-tenant-form'>

          <FieldsWrapper>
            <Field name='id' id='id' type='text'>
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label='Organization ID'
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && !!meta.error ? getOrganizationErrorMessage(meta.error) : ' '}
                  className='buyer-pro-input'
                />
              )}
            </Field>

            <Field name='name' id='name' type='text'>
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label='Organization Name'
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && !!meta.error ? 'Organization name is required' : ' '}
                  className='buyer-pro-input'
                />
              )}
            </Field>
          </FieldsWrapper>

          <ButtonContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              size='large'
              onClick={() => {
                if (!isValid) {
                  setAlert({
                    open: true,
                    severity: 'error',
                    title: 'Please complete the form',
                  });
                }
              }}
            >
              Create
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationForm;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 8px 56px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;
