import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from '@emotion/styled';
import NameCard from './cards/NameCard';
import MediaCard from './cards/MediaCard';
import TargetingCard from './cards/TargetingCard';
import InventoryCard from './cards/InventoryCard';
import { CountryDto } from '../../../../api/services/dto/country-dto';
import { BuyerSeatDto } from '../../../../api/services/dto/buyer-seat-dto';
import { AdFormat } from '../../../../api/services/dto/ad-format';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  DealCreateFormData,
  DEVICE_TYPE,
  filterByMediaType,
  getDealCreateFormInitValues,
  getDealCreateFormValidationSchema,
  GetPublishersParams,
  GetSitesParams,
  LANGUAGE,
  MEDIA_TYPE,
  SelectOption,
} from '../../../../util/deals-util';
import { applicationService } from '../../../../api/services/application.service';
import { PublisherDto } from '../../../../api/services/dto/publisher-dto';
import { SiteDto } from '../../../../api/services/dto/site-dto';
import InventoryCardSiteSelection from './cards/InventoryCardSiteSelection';
import debounce from 'lodash.debounce';
import {  DealActionStep, DuplicateStep, SharedState, SubmitStep, UpdateState } from '../Create';
import { useAlert } from '../../../shared/AlertProvider';
import { buyerSeatService } from '../../../../api/services/buyer-seat.service';
import { useParams } from 'react-router-dom';

// Moved outside the functional component, because local variables in a functional components are not preserved across re-renders
// https://blog.devgenius.io/how-to-create-a-real-time-search-using-debounce-in-react-js-846a62ad2198
const getSitesOnSearchStringChange = async (
  params: GetSitesParams,
  cb: (data: SiteDto[]) => void
) => {
  const data: SiteDto[] = await applicationService.getSites(params);
  cb(data);
};

const debouncedGetSites = debounce((params, cb) => {
  getSitesOnSearchStringChange(params, cb).catch((reason) => {
    console.error(`Failed to fetch sites: ${reason}`);
  });
}, 500);


const DealSettings = forwardRef<DealActionStep,  SharedState & UpdateState>(
  ({ updateState, dealId }, ref) => {
    const { setAlert } = useAlert();
    const [customDirtyState, setDirtyState] = useState<boolean>(false);

    /*** Name Card ***/

    const [buyerSeats, setBuyerSeats] = useState<SelectOption[]>([]);

    /*** Media Card ***/
    const [mediaType, setMediaType] = useState<MEDIA_TYPE>(MEDIA_TYPE.BANNER);
    const [adFormats, setAdFormats] = useState<AdFormat[]>([]);
    const [filteredAdFormats, setFilteredAdFormats] = useState<AdFormat[]>([]);

    /*** Targeting Card ***/
    const [countries, setCountries] = useState<CountryDto[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
    const [deviceTypes, setDeviceTypes] = useState<DEVICE_TYPE[]>([DEVICE_TYPE.DESKTOP]);
    const [selectedLanguage, setSelectedLanguage] = useState<LANGUAGE>(LANGUAGE.DE);

    /*** Inventory Card ***/
    const [publishers, setPublishers] = useState<PublisherDto[]>([]);
    const [selectedPublisher, setSelectedPublisher] = useState<PublisherDto | null>(null);
    const [siteSearchString, setSiteSearchString] = useState('');
    const [sites, setSites] = useState<SiteDto[]>([]);
    const [sitesToFilter, setSitesToFilter] = useState<SiteDto[]>([]);
    const [savedSites, setSavedSites] = useState<number[]>([]);

    const [formValues, setFormValues] = useState<DealCreateFormData>(() =>
      getDealCreateFormInitValues()
    );
    const { id } = useParams();

    const validationSchema = getDealCreateFormValidationSchema();
    const formikRef = useRef<FormikProps<DealCreateFormData>>(null);

    let submitFormRef: SubmitStep = (): Promise<void> => {
      console.log(`ref submit`);
      let formikProps = formikRef.current;
      if (formikProps) {
        if (formikProps.isValid) return formikProps.submitForm();
        else {
          // This ensures we don't submit an empty form.
          // TODO: How to trigger all field errors instead?
          // Note: This only triggers (isValid == false) when validateOnMount={true} is set further down.
          ['name', 'buyerSeat', 'targetCountries', 'adFormats', 'siteList'].forEach(
            (field) => formikProps?.setFieldTouched(field, true, true)
          );
          return Promise.reject(new Error('Please complete the form'));
        }
      }
      return Promise.reject(new Error('Could not reference access formik form properties.'));
    };

    const duplicateFormRef: DuplicateStep = async (id: number): Promise<any> => {
      console.log(`ref duplicate for id: ${id}`);
      return applicationService.duplicateDeal(id);

    };

    useImperativeHandle(ref, () => ({submitFunc:submitFormRef,
    duplicateFunc:duplicateFormRef


    }));

    useEffect(() => {
      if (dealId !== undefined) {
        applicationService
          .getDeal(dealId)
          .then((deal) => {
            // Set initial state with deal data
            setFormValues({
              name: deal.name,
              adFormats: deal.formats.map((format) => format.id),
              brandSafetyLevel: deal.brandSafetyLevel,
              buyerSeat: deal.buyerSeatId,
              description: deal.description,
              deviceTypes: deal.devices,
              language: deal.language,
              mediaType: deal.formats[0].type as MEDIA_TYPE,
              siteList: [],
              targetCountries: [],
            });
            setSelectedLanguage(deal.language);
            setSelectedCountries(deal.countries);
            setSavedSites(deal.siteList);
            setMediaType(deal.formats[0].type as MEDIA_TYPE);
            updateState!({ dealId: deal.id, segmentId: deal.segment?.id });
          })
          .catch((reason) =>
            setAlert({
              open: true,
              severity: 'error',
              title: 'Failed to fetch deal',
              message: reason.message,
            })
          );
          
      }
    }, [id]);

    useEffect(() => {
      const getBuyerSeats = async () => {
        const data: BuyerSeatDto[] = await buyerSeatService.getBuyerSeats();

        // Transform data for the buyer seat select component
        const options: SelectOption[] = data.map(
          (buyerSeat: BuyerSeatDto): SelectOption => ({
            value: buyerSeat.id,
            label: buyerSeat.buyerSeatNameAndId,
          })
        );
        setBuyerSeats(options);
      };

      getBuyerSeats().catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch buyer seats',
          message: reason.message,
        })
      );
    }, []);

    useEffect(() => {
      const getAdFormats = async () => {
        const data: AdFormat[] = await applicationService.getAdFormats();
        setAdFormats(data);
      };

      getAdFormats().catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch ad formats',
          message: reason.message,
        })
      );
    }, []);

    useEffect(() => {
      const filteredData: AdFormat[] = filterByMediaType(adFormats, mediaType);
      setFilteredAdFormats(filteredData);
    }, [adFormats, mediaType]);

    useEffect(() => {
      const getCountries = async () => {
        const data: CountryDto[] = await applicationService.getCountries();
        const sortedCountries: CountryDto[] = data.sort((a: CountryDto, b: CountryDto) =>
          a.name.localeCompare(b.name)
        );
        setCountries(sortedCountries);
      };

      getCountries().catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch countries',
          message: reason.message,
        })
      );
    }, []);

    /*** Triggers initially with default value of language = 'DE', and every time user changes language ***/
    useEffect(() => {
      const getSites = async (params: GetSitesParams) => {
        const siteData: SiteDto[] = await applicationService.getSites(params);
        const sortedSiteData: SiteDto[] = siteData.sort((a: SiteDto, b: SiteDto) =>
          a.name.localeCompare(b.name)
        );
        const populatedWithSelectedProperty: SiteDto[] = sortedSiteData.map(
          (site: SiteDto): SiteDto => ({
            ...site,
            selected: true,
          })
        );
        setSites(populatedWithSelectedProperty);
      };

      const getPublishers = async () => {
        const publisherParams: GetPublishersParams = {
          languages: selectedLanguage.toLowerCase(),
        };
        const publisherData: PublisherDto[] = await applicationService.getPublishers(
          publisherParams
        );

        const sortedPublisherData: PublisherDto[] = publisherData.sort(
          (publisher1: PublisherDto, publisher2: PublisherDto) =>
            publisher1.name.localeCompare(publisher2.name)
        );
        setPublishers(sortedPublisherData);

        // Get sites based on selected publishers, selected language and site search string
        const siteParams: GetSitesParams = {
          languages: selectedLanguage.toLowerCase(),
          publisher_ids: publisherData
            .map((publisher: PublisherDto) => publisher.id.toString())
            .join(','),
          name: '',
        };
        getSites(siteParams).catch((reason) =>
          setAlert({
            open: true,
            severity: 'error',
            title: 'Failed to fetch sites',
            message: reason.message,
          })
        );
      };

      getPublishers().catch((reason) =>
        setAlert({
          open: true,
          severity: 'error',
          title: 'Failed to fetch publishers',
          message: reason.message,
        })
      );
    }, [selectedLanguage]);

    const handleMediaTypeChange = (newMediaType: MEDIA_TYPE) => {
      setMediaType(newMediaType);
      const formats: AdFormat[] = filterByMediaType(adFormats, newMediaType);
      setFilteredAdFormats(formats);
    };

    const handleLanguageChange = (newLanguage: LANGUAGE) => {
      // Reset selected publisher
      setSelectedPublisher(null);

      // Reset site search string
      setSiteSearchString('');

      if (dealId) {
        setSavedSites([]);
      }

      // Trigger GET publishers API call every time user changes language in Targeting card
      // After getting publishers, GET sites for all the publishers is called
      setSelectedLanguage(newLanguage);
    };

    const handleGetSitesSuccess = (sites: SiteDto[]) => {
      if (sites.length) {
        const sortedSites: SiteDto[] = sites.sort((a: SiteDto, b: SiteDto) =>
          a.name.localeCompare(b.name)
        );
        setSitesToFilter(sortedSites);
      } else {
        setSitesToFilter([]);
      }
    };

    const handlePublisherSelect = async (newPublisherId: number) => {
      const newPublisher = publishers.filter((option) => option.id === newPublisherId)[0];

      // Get sites for selected publisher only
      setSelectedPublisher(newPublisher);

      // Reset search string
      setSiteSearchString('');

      const params: GetSitesParams = {
        languages: selectedLanguage.toLowerCase(),
        publisher_ids: newPublisher
          ? newPublisher.id.toString()
          : publishers.map((publisher: PublisherDto) => publisher.id.toString()).join(','),
        name: '',
      };
      const siteData: SiteDto[] = await applicationService.getSites(params);
      handleGetSitesSuccess(siteData);
    };

    const handleSiteSearchStringChange = async (newSearchString: string) => {
      setSiteSearchString(newSearchString);

      const params: GetSitesParams = {
        languages: selectedLanguage.toLowerCase(),
        publisher_ids: selectedPublisher
          ? selectedPublisher.id.toString()
          : publishers.map((publisher: PublisherDto) => publisher.id.toString()).join(','),
        name: newSearchString,
      };
      debouncedGetSites(params, (data: SiteDto[]) => {
        handleGetSitesSuccess(data);
      });
    };

    const createDeal = async (body: any, actions: any) => {
      const deal: any = await applicationService.createDeal(body);
      // Tell parent to enable the stepper Next button
      updateState!({ dealId: deal.id });
      console.log(`Deal (ID: ${deal.id}) successfully created:`, deal);
      actions.setSubmitting(false);
      actions.resetForm();
    };

    const updateDeal = async (id: number, body: any, actions: any) => {
      console.log(body);

      const deal: any = await applicationService.updateDeal(id, body);
      // Tell parent to enable the stepper Next button
      console.log(`Deal (ID: ${deal.id}) successfully updated:`, deal);
      actions.setSubmitting(false);
      actions.resetForm();
    };

    const handleSubmit = (
      values: DealCreateFormData,
      actions: FormikHelpers<DealCreateFormData>
    ) => {
      actions.setSubmitting(true);

      const body = {
        name: values.name,
        buyerSeatId: values.buyerSeat,
        banners: mediaType === MEDIA_TYPE.BANNER ? [...values.adFormats] : [],
        natives: mediaType === MEDIA_TYPE.NATIVE ? [...values.adFormats] : [],
        countries: values.targetCountries.map((country: CountryDto) => country.id),
        siteList: values.siteList
          .filter((site: SiteDto) => site.selected)
          .map((site: SiteDto) => site.id),
        language: values.language,
        description: values.description,
        devices: values.deviceTypes,
        brandSafetyLevel: values.brandSafetyLevel,
      };

      if (!dealId) {
        console.log('Deal creation body:', body);

        createDeal(body, actions).catch((reason) =>
          setAlert({
            open: true,
            severity: 'error',
            title: 'Failed to create a deal',
            message: reason.message,
          })
        );
      } else if (customDirtyState) {
        console.log('Deal update body:', body);

        updateDeal(dealId, body, actions).catch((reason) =>
          setAlert({
            open: true,
            severity: 'error',
            title: 'Failed to update a deal',
            message: reason.message,
          })
        );
      } else {
        return Promise.resolve();
      }
    };

    return (
      <StepWrapper>
        <StepTitle>{dealId !==undefined ?'Edit deal': 'Create a new deal'}</StepTitle>

        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={(values: DealCreateFormData, actions: FormikHelpers<DealCreateFormData>) =>
            handleSubmit(values, actions)
          }
        >
          {(props: FormikProps<any>) => (
            <Form
              onChange={() => {
                setDirtyState(true);
              }}
              data-testid="create-deal-form"
            >
              <NameCard dealId={dealId} buyerSeats={buyerSeats} />
              <MediaCard
                setCustomDirtyState={() => setDirtyState(true)}
                adFormats={filteredAdFormats}
                onMediaTypeChange={handleMediaTypeChange}
                helperText={!!props.errors?.adFormats && !!props.touched?.adFormats}
              />
              <TargetingCard
                setCustomDirtyState={() => setDirtyState(true)}
                name={'targetCountries'} // important for rendering
                countries={countries}
                selected={selectedCountries}
                onLanguageChange={handleLanguageChange}
                touchedTargetCountries={props.touched?.targetCountries ?? false}
                errorTargetCountries={props.errors?.targetCountries ?? undefined}
              />
              <InventoryCard>
                <InventoryCardSiteSelection
                  publishers={publishers}
                  sites={sites}
                  sitesToFilter={sitesToFilter}
                  selectedPublisher={selectedPublisher}
                  onPublisherSelect={handlePublisherSelect}
                  searchString={siteSearchString}
                  onSearchStringChange={handleSiteSearchStringChange}
                  propSavedSites={savedSites}
                  setCustomDirtyState={() => setDirtyState(true)}
                  helperText={!!props?.errors.siteList && !!props.touched?.siteList}
                />
              </InventoryCard>
            </Form>
          )}
        </Formik>
      </StepWrapper>
    );
  }
);

export default DealSettings;

const StepWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 32px 8px 16px;
  text-align: justify;
`;

const StepTitle = styled.h2`
  margin-bottom: 32px;
`;
