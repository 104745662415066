import { TextFieldClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomTextField: Partial<OverridesStyleRules<TextFieldClassKey>> | undefined = {
  root: {
    '&.buyer-pro-input': {
      width: '480px',
      '&.wider': {
        width: '725px',
      },
    },

    '&.buyer-pro-search': {
      width: '350px',
      marginTop: 0,
      marginBottom: 0,

      '& .MuiOutlinedInput-root': {
        paddingRight: 0,
        color: getColor('text'),

        '& .MuiOutlinedInput-input': {
          borderRadius: '4px',

          '&::placeholder': {
            color: '#000',
          },
        },
      },
    },

    '&.sites-search': {
      width: '100%',
    },

    '&.buyer-pro-select': {
      width: '480px',

      '&.page-position-select': {
        width: '240px',
      },

      '&.publisher-select': {
        width: '100%',
      },

      '& .MuiOutlinedInput-root': {
        color: getColor('text'),

        '& .MuiSvgIcon-root': {
          right: '7px',
        },
      },
    },
  },
};
