import React, { createContext, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

export interface Action {
  label: string,
  function: (i?: any) => Promise<any>
}

export interface AlertProps {
  open: boolean;
  title: string;
  message?: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  action?: Action
}

interface AlertContextType {
  alert: AlertProps;
  setAlert: Dispatch<SetStateAction<AlertProps>>;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alert, setAlert] = React.useState<AlertProps>({
    open: false,
    title: '',
    message: '',
    severity: 'info',
  });

  return <AlertContext.Provider value={{ alert, setAlert }}>{children}</AlertContext.Provider>;
};
