// ******************************************************  TYPE  ****************************************************** //
export type UIVar = keyof typeof uiVariables;

// ******************************************************  FUNCTIONS  ****************************************************** //
export function getVar(v: UIVar) {
  return `var(${uiVariables[v]})`;
}

// ******************************************************  VARIABLES  ****************************************************** //
const uiVariables = {
  mainPadding: '--mainPadding',
  headerHeight: '--headerHeight',
  sidebarWidth: '--sidebarWidth',
  borderRadius: '--borderRadius',
  htmlFontSize: '--htmlFontSize',
};
