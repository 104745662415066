import React, { RefObject, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { StepLabelProps } from '@mui/material/StepLabel/StepLabel';
import Summary from './summary/Summary';
import DealSettings from './deal-settings/DealSettings';
import CreateSegment from './create-segment/CreateSegment';
import { useAlert } from "../../shared/AlertProvider";
import { scrollOnTopOfMain } from '../../shared/ScrollToTop';

/**
 * Each step provides its own submit function using a Ref object that is referenced from this (parent) component.
 * This allows leaving the specific submit logic to the child components, while keeping an overall state of the multi-step
 * form in this component, including error handling.
 *
 * https://www.ombulabs.com/blog/react/javascript/how-to-export-instance-methods-from-react-function-components.html
 */
export type SubmitStep = () => Promise<void>;

export type DuplicateStep = (id: number) => Promise<any>

export type DealActionStep = {
  submitFunc: SubmitStep;
  duplicateFunc: DuplicateStep;

}


/**
 * Properties shared between the steps.
 */
export type SharedState = {
  dealId?: number;
  segmentId?: number;
};

/**
 * Allows updating the shared properties from inside a Step component. E.g. pass a dealId to
 * the next Step.
 */
export type UpdateState = {
  updateState: (state: SharedState) => void;
};

/**
 * Sort of inspired by this: https://fullstacksoup.blog/2021/10/01/react-material-ui-stepper-form-example/
 * Motivation for this: Keep the overall logic for handling the form validation and submit on the parent
 * (e.g. use the buttons provided by the stepper) whilst delegating the logic to the individual component.
 */
const Create = () => {
  const { setAlert } = useAlert();

  const [activeStep, setActiveStep] = useState(0);

  const [shared, setShared] = useState<SharedState>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const dealId = id ? parseInt(id) : undefined;
  let step;

  useEffect(() => {
    setShared((before) => ({ ...before, dealId }))
  }, [dealId]);

  useEffect(() => {
    scrollOnTopOfMain()
  }, [activeStep]);

  const steps: StepProps[] = [
    {
      label: 'Deal settings',
      optional: false,
    },
    {
      label: 'Add Contextual Targeting',
      optional: true,
    },
    {
      label: 'Summary',
      optional: true,
    },
  ];

  const handleNext = (submitRef: RefObject<SubmitStep>) => {
    if (submitRef.current) {
      submitRef.current()
        .then(() => {
          if (activeStep < steps.length - 1) {

            setActiveStep((prev) => prev + 1);
          } else {
            navigate('/');
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            severity: 'error',
            title: err.message,
            message: err.options?.cause
          })
        });
    }
  };

  const updateState = (state: SharedState) => setShared(state);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      navigate('/');
    }
  };

  const handleDuplicate = (id: number, action: RefObject<DuplicateStep>) => {
    let duplicate = action.current
    if (duplicate) {

      duplicate(id).then((deal: any) => {
        console.log(`Duplicate successed ${deal.id}`)
        setAlert({ open: true, title: 'Deal successfully duplicated', severity: 'success' });
        
        navigate(`../edit/${deal.id}`)
      })
        .catch((err) => {
          setAlert({
            open: true,
            severity: 'error',
            title: err.message,
            message: err.options?.cause
          })
        });




    }

  };


  switch (activeStep) {
    case 0:
      step = <DealStep dealId={dealId} updateState={updateState} handleDuplicate={handleDuplicate} handleSubmit={handleNext} />
      break
    case 1:
      step = <SegmentStep updateState={updateState} handleBack={handleBack} handleSkip={handleSkip} handleSubmit={handleNext} dealId={shared.dealId!} segmentId={shared.segmentId} />
      break
    case 2:
      step = <SummaryStep handleBack={handleBack} saveAsDraft={() => navigate('/')} handleSubmit={handleNext} dealId={shared.dealId!} />
      break
  }
  return (
    <PageWrapper>
      <StepLabels steps={steps} activeStep={activeStep} />
      {step}
    </PageWrapper>
  );
};

/**
 * Defines the step properties used for displaying the overview of the steps.
 * IMO:
 * The usage of that Mui Step component here is a complete waste. It's literally only used for the top labels :facepalm:
 */
type StepProps = {
  label: string;
  optional: boolean;
};

function StepLabels(props: { steps: StepProps[], activeStep: number }) {
  return (
    <Stepper activeStep={props.activeStep}>
      {props.steps.map((step: StepProps) => {
        const labelProps: StepLabelProps = {};
        if (step.optional) {
          labelProps.optional = <Typography variant="caption">Optional</Typography>;
        }
        return (
          <Step key={step.label}>
            <StepLabel {...labelProps}>{step.label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  )
}

export type DealStepProps = {
  updateState: (state: SharedState) => void;
  handleSubmit: (submit: RefObject<SubmitStep>) => void;
  handleDuplicate: (id: number, duplicate: RefObject<DuplicateStep>) => void;
  dealId?: number;
}

function DealStep(props: DealStepProps) {
  const submitRef: React.MutableRefObject<SubmitStep | null> = useRef<SubmitStep>(null);
  const duplicateRef: React.MutableRefObject<DuplicateStep | null> = useRef<DuplicateStep>(null);


  return <>
    <DealSettings dealId={props.dealId} updateState={props.updateState}
      ref={(node: DealActionStep) => {
        if (node) {
          submitRef.current = node.submitFunc;
          duplicateRef.current = node.duplicateFunc;
        }
      }} />
    <NavigationButtonsContainer>
      <Button
        size="medium"
        className="tertiary-button"
        disabled={true}
      >
        Back
      </Button>

      <Box sx={{ flex: '1 1 auto' }} />
      <Button
        size="medium"
        className="tertiary-button"

        disabled={props.dealId === undefined}
        onClick={() => props.handleDuplicate(props.dealId ?? 0, duplicateRef)}
        sx={{ mr: 1 }}
      >
        Duplicate
      </Button>
      <Button size="medium" variant="contained" onClick={() => props.handleSubmit(submitRef)}>
        Save and next
      </Button>
    </NavigationButtonsContainer>
  </>;
}

type SegmentStepProps = {
  updateState: (state: SharedState) => void,
  handleBack: () => void,
  handleSkip: () => void,
  handleSubmit: (submit: RefObject<SubmitStep>) => void,
  dealId: number,
  segmentId?: number
};

function SegmentStep(props: SegmentStepProps) {
  const submitRef = useRef<SubmitStep>(null)
  return <>
    <CreateSegment
      dealId={props.dealId}
      segmentId={props.segmentId}
      updateState={props.updateState}
      ref={submitRef}
    />
    <NavigationButtonsContainer>
      <Button
        size="medium"
        className="tertiary-button"
        onClick={props.handleBack}
      >
        Back
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button size="medium" className="tertiary-button" onClick={props.handleSkip} sx={{ mr: 1 }}>
        Skip
      </Button>
      <Button size="medium" variant="contained" onClick={() => props.handleSubmit(submitRef)}>
        Save and next
      </Button>
    </NavigationButtonsContainer>
  </>
}

type SummaryStepProps = {
  handleBack: () => void,
  saveAsDraft: () => void,
  handleSubmit: (submit: RefObject<SubmitStep>) => void,
  dealId: number
};

function SummaryStep(props: SummaryStepProps) {
  const submitRef = useRef<SubmitStep>(null)

  return <>
    <Summary dealId={props.dealId!} ref={submitRef} />
    <NavigationButtonsContainer>
      <Button
        size="medium"
        className="tertiary-button"
        onClick={props.handleBack}
      >
        Back
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button size="medium" className="tertiary-button" onClick={props.saveAsDraft} sx={{ mr: 1 }}>
        Save as Draft
      </Button>
      <Button size="medium" variant="contained" onClick={() => props.handleSubmit(submitRef)}>
        Publish
      </Button>
    </NavigationButtonsContainer>
  </>
}


export default Create;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
`;

const NavigationButtonsContainer = styled.div`
  padding: 24px 8px 56px;
  display: flex;
`;
