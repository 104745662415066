/*
 * https://developer.auth0.com/resources/guides/spa/react/basic-authentication#configure-a-react-authentication-provider
 * This is sort of a placeholder component, from the docs:

 * This component will only render the navigation bar and an empty content container to help you create a smooth transition
 * between a route with no content, /callback, to a route with content, such as the /profile page.
 */
import styled from '@emotion/styled';

import { useLocation } from 'react-router-dom';
import { Logout } from './auth/Logout';


export const Callback = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  return <>
    <p>Logged in...</p>
    {error === 'access_denied' ?
      (<ErrorMessage>
          <p>
            Your login did not succeed:
            <br />
            <br />
            {errorDescription}
          </p>

          <p>
            Please try to <Logout /> and log in again using your username and password.
            <br />
          </p>
          <p>
            If that does not help please contact our <a href='mailto:buyer@cwire.com'>support</a>.
            <br />
          </p>
        </ErrorMessage>

      ) : (<></>)
    }

  </>;
};
const ErrorMessage = styled.div`
    word-wrap: break-word;
`;
