import { useAuth0 } from "@auth0/auth0-react";
import { StyledLink } from "../../util/styles-util";
import LogoutIcon from '@mui/icons-material/Logout';

export const Logout = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      },
    });
  };

  return (
    <StyledLink  onClick={handleLogout} >
      <LogoutIcon sx={{ fontSize: 20, ml: -0.3, mr: 2 }}/>
      Log Out
    </StyledLink>
  );
};