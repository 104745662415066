import applicationApi from '../api';

export interface CreateSegmentRes {
  id: number;
  name: string;
}
export interface CreateSegmentReq {
  dealId: number;
  name: string;
  includes: {
    categories: string[];
    keywords: string[];
  };
  excludes: {
    categories: string[];
    keywords: string[];
  };
}
export interface UpdateSegmentReq {
  name: string;
  includes: {
    categories: string[];
    keywords: string[];
  };
  excludes: {
    categories: string[];
    keywords: string[];
  };
}

export interface SegmentRes {
  id: number;
  name: string;
  includes: {
    categories: string[];
    keywords: string[];
  };
  excludes: {
    categories: string[];
    keywords: string[];
  };
}

export const SegmentService = {
  create: (data: CreateSegmentReq): Promise<CreateSegmentRes> => {
    return applicationApi.post('/segments', data).then((res) => res.data);
  },
  get: (id: number): Promise<SegmentRes> => {
    return applicationApi.get(`/segments/${id}`).then((res) => res.data);
  },
  update: (id: number, data: UpdateSegmentReq): Promise<SegmentRes> => {
    return applicationApi.put(`/segments/${id}`, data).then((res) => res.data);
  },
};
