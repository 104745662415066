import styled from '@emotion/styled';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { getColor, getLighterColor } from '../../../theme/colors/colors';
import cWireLogo from '../../../assets/images/cwire-logo.svg';

const Header = () => {
  return (
    <MainHeader>
      <img src={cWireLogo} alt="Cwire Logo" />
      <StyledText>Buyer Pro</StyledText>
    </MainHeader>
  );
};

export default Header;

const MainHeader = styled.header`
  width: 100%;
  height: ${getVar('headerHeight')};
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${getColor('primary')};
  color: ${getColor('primaryContrast')};
  border-bottom: 1px solid ${getLighterColor('primary', 22)};
`;

const StyledText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.15px;
  user-select: none;
`;
