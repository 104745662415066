import applicationApi from '../api';
import { PageableDealOverviewDto } from './dto/pageable-deal-overview-dto';
import { BuyerSeatDto } from './dto/buyer-seat-dto';
import { GetDealsQueryParams, MEDIA_TYPE } from '../../util/deals-util';
import { CreateDealResponseDto } from './dto/create-deal-response-dto';
import { BannerDto } from './dto/banner-dto';
import { NativeDto } from './dto/native-dto';
import { AdFormat } from './dto/ad-format';
import { DealDto } from './dto/deal-dto';
import { CountryDto } from './dto/country-dto';
import { SiteDto } from './dto/site-dto';
import { SegmentRes, UpdateSegmentReq } from './segment.service';

const getDeals = async (params: GetDealsQueryParams): Promise<PageableDealOverviewDto> => {
  const response = await applicationApi.get('/deal', { params });
  return response.data;
};

const getDeal = async (id: number | undefined): Promise<DealDto> => {
  const response = await applicationApi.get(`/deal/${id}`);
  return response.data;
};

const createDeal = async (body: any): Promise<CreateDealResponseDto> => {
  const response = await applicationApi.post('/deal', body);
  return response.data;
};

const updateDeal = async (id: number, body: any): Promise<CreateDealResponseDto> => {
  const response = await applicationApi.patch(`/deal/${id}`, body);
  return response.data;
};

const getAdFormats = async (): Promise<AdFormat[]> => {
  const response = await applicationApi.get('/media-format');
  return [
    ...response.data.banners.map((banner: BannerDto) => ({
      id: banner.id,
      name: `${banner.width}x${banner.height}`,
      mediaType: MEDIA_TYPE.BANNER,
    })),
    ...response.data.natives.map((native: NativeDto) => ({
      id: native.id,
      name: native.name,
      mediaType: MEDIA_TYPE.NATIVE,
    })),
  ];
};

const getCountries = async (): Promise<CountryDto[]> => {
  const response = await applicationApi.get('/countries');
  return response.data;
};

const getSelectedCountries = async (id: number | null): Promise<CountryDto[]> => {
  const response = await applicationApi.get(`/countries/selected?id=${id}`);
  return response.data;
};

const publishDeal = async (id: number, floorPrice: number) => {
  const body = {id, floorPrice};
  return await applicationApi.post(`deal/publish`, body);
};

const getSites = async (params: any): Promise<SiteDto[]> => {
  const response = await applicationApi.get('/adserver/sites', { params });
  return response.data;
};

const getSelectedSites = async (id: number | null): Promise<SiteDto[]> => {
  const response = await applicationApi.get(`/adserver/sites/selected?deal_id=${id}`);
  return response.data;
};

const getPublishers = async (params: any): Promise<any> => {
  const response = await applicationApi.get('/adserver/publishers', { params });
  return response.data;
};

const duplicateDeal =  async (id: any): Promise<any> => {
  
  try {
    const response = await applicationApi.post(`/deal/${id}/duplicate`);
    console.log("Response duplicate data", response.data);
    return response.data;
  } catch (error:any) {
    if (error.response) {
      // Handle the error response from the server
      console.error("Error status:", error.response.status);
      console.error("Response data:", error.response.data);
      return Promise.reject(new Error(error.response.data)); // You can throw the error if needed
    } else if (error.request) {
      // Handle the case where no response was received
      console.error("No response received:", error.request);
      return Promise.reject(new Error("No response received from the server"));
    } else {
      // Handle other types of errors
      console.error("Error", error.message);
      return Promise.reject(new Error(error.message));
    }
  }
 
}
export const applicationService = {
  getDeals,
  getDeal,
  createDeal,
  updateDeal,
  getAdFormats,
  getCountries,
  publishDeal,
  getSites,
  getPublishers,
  getSelectedCountries,
  getSelectedSites,
  duplicateDeal,
};
