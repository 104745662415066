import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export const scrollOnTopOfMain = () => {
    const main = document.documentElement.querySelector("Main")
    if (main)
        main.scrollTo(0, 0)
}

const ScrollToTop = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
        scrollOnTopOfMain();
    }, [location.pathname]);
    return children
}

export default ScrollToTop;