import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Field } from 'formik';
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import { SelectOption } from '../../../../../util/deals-util';

const NameCard = ({
  buyerSeats,
  dealId,
}: {
  buyerSeats: SelectOption[];
  dealId: number | undefined;
}) => {
  return (
    <Card elevation={4} sx={{ marginBottom: '32px' }}>
      <CardContent>
        <CardTitle>Name</CardTitle>

        <NameAndBuyerSeatWrapper>
          <Field name="name" id="name" type="text">
            {({ field, meta }: any) => (
              <TextField
                {...field}
                label="Deal Name"
                error={!!meta.touched && !!meta.error}
                helperText={!!meta.touched && !!meta.error ? 'Deal name is required' : ' '}
                id="deal-creation-name-input"
                className="buyer-pro-input"
                inputProps={{
                  'data-testid': 'deal-creation-name-input',
                }}
                variant="outlined"
              />
            )}
          </Field>

          <Field name="buyerSeat" id="buyerSeat">
            {({ field, meta }: any) => (
              <TextField
                {...field}
                label="Buyer Seat"
                error={!!meta.touched && !!meta.error}
                helperText={!!meta.touched && !!meta.error ? 'Buyer seat is required' : ' '}
                id="deal-creation-buyerSeat-select"
                className="buyer-pro-select"
                select
                inputProps={{
                  'data-testid': 'deal-creation-buyerSeat-select',
                }}
                variant="outlined"
                disabled={!!dealId}
              >
                {buyerSeats.map((option: SelectOption) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
        </NameAndBuyerSeatWrapper>
      </CardContent>
    </Card>
  );
};

export default NameCard;

const CardTitle = styled.h3`
  margin-bottom: 16px;
`;

const NameAndBuyerSeatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
