import { BuyerSeatDto } from './dto/buyer-seat-dto';
import { adminApi } from '../admin-api';
import { BuyerSeatRequestDto } from './dto/buyer-seat-request-dto';
import applicationApi from '../api';


const createBuyerSeat = async (body: BuyerSeatRequestDto): Promise<BuyerSeatDto> => {
  const response = await adminApi.post('/buyer-seat', body);
  return response.data;
};

const getBuyerSeats = async (): Promise<BuyerSeatDto[]> => {
  const response = await applicationApi.get('/buyer-seat');
  return response.data.map(
    (buyerSeat: BuyerSeatDto): BuyerSeatDto => ({
      ...buyerSeat,
      buyerSeatNameAndId: `${buyerSeat.name} (${buyerSeat.id})`, // populate frontend specific field
    })
  );
};


export const buyerSeatService = {
  createBuyerSeat,
  getBuyerSeats,
};
