import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import React, { useRef, useState } from 'react';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { useAlert } from '../../shared/AlertProvider';
import { warrantService } from '../../../api/services/warrant.service';
import { WarrantDto } from '../../../api/services/dto/user-managment-dto/warrant-dto';

import SelectUser from './cards/SelectUser';
import SelectOrganization from './cards/SelectOrganization';
import { UserDto } from '../../../api/services/dto/user-managment-dto/user-dto';
import { OrganizationDto } from '../../../api/services/dto/user-managment-dto/organization-dto';

export type UserToOrganization = {
  user: UserDto;
  organization: OrganizationDto;
}

export const validate = (values: UserToOrganization): FormikErrors<UserToOrganization> => {
  const errors: FormikErrors<UserToOrganization> = {};
  console.log(`Validate: ${JSON.stringify(values)}`);
  if (!values?.user?.userId) {
    errors.user = { userId: 'User is required', email: '' };
  }

  if (!values.organization?.organizationId) {
    errors.organization = { organizationId: 'Organization is required', name: '' };
  }

  return errors;
};
const AssignUserToOrganizationForm = () => {
  const formikRef = useRef<FormikProps<UserToOrganization>>(null);
  const { setAlert } = useAlert();
  const [customDirtyState, setDirtyState] = useState<boolean>(false);
  const [initial, setInitial] = useState<UserToOrganization>({
    user: { userId: '', email: '' },
    organization: { organizationId: '', name: '' },
  });


  const assignUserToOrganization = async (value: UserToOrganization) => {
    console.log(`Submit: ${JSON.stringify(value)}`);
    console.log(customDirtyState);

    if (value.user && value.organization) {

      const request: WarrantDto = { userId: value.user?.userId, organizationId: value.organization?.organizationId };

      await warrantService
        .assignUserToOrganization(request)
        .then(() => {
          setAlert({ open: true, title: 'Assigned the user to the organization', severity: 'success' });
        })
        .catch((reason) => {
          console.error(`Failed to assign user to organization: ${reason}`);
          setAlert({
            open: true,
            title: 'Failed to assign user to organization',
            message: reason.message,
            severity: 'error'
          });
        });
      setInitial({
        user: { userId: '', email: '' },
        organization: { organizationId: '', name: '' },
      });
    }
  };




  return (

    <Formik
      innerRef={formikRef}
      initialValues={initial}
      onSubmit={assignUserToOrganization}
      validate={validate}
      validateOnBlur={true}
      validateOnMount={true}

    >
      {({ errors, touched }) => (
        <Form
          onChange={() => {
            setDirtyState(true);
          }}
          id='create-deal-form'
        >
          <FieldsWrapper>
            <SelectUser
              selected={initial.user}
              name='user'
              id='user'
              setCustomDirtyState={() => setDirtyState(true)}

              helperText={!!(errors?.user?.userId && touched?.user)}
            />


            <SelectOrganization
              selected={initial.organization}
              name='organization'
              id='organization'
              setCustomDirtyState={() => setDirtyState(true)}

              helperText={!!(errors?.organization?.organizationId && touched?.organization)}
            />


          </FieldsWrapper>


          <ButtonContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              size='large'
              onClick={() => {
                if (!formikRef.current?.isValid) {
                  setAlert({
                    open: true,
                    severity: 'error',
                    title: 'Please complete the form',
                  });
                }
              }}
            >
              Assign
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default AssignUserToOrganizationForm;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 8px 56px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

