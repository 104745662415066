import { SyntheticEvent, useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { FieldHookConfig } from 'formik/dist/Field';
import { OrganizationDto } from '../../../../api/services/dto/user-managment-dto/organization-dto';
import { warrantService } from '../../../../api/services/warrant.service';

export const SelectOrganization = (
  props: {
    selected: OrganizationDto;
    setCustomDirtyState: () => void;
    helperText: boolean;
  } & FieldHookConfig<OrganizationDto>
) => {
  const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
  const [selected, setSelected] = useState<OrganizationDto>(props.selected);
  const [field, _, helper] = useField(props);

  // Update state when props change. TODO: Can this be done better?
  useEffect(() => {
    warrantService.fetchOrganizations().then((organizations) => {
      setOrganizations(organizations);
    });
    setSelected(props.selected);
  }, [props]);

  /**
   * Used for setting the selected values from props
   * This would not be needed it the AutoComplete component would be using the ids instead of objects:
   * https://github.com/mui/material-ui/issues/23708
   * https://github.com/mui/material-ui/issues/37287
   */
  useEffect(() => {
    // reactive dependencies ftw.
    if (organizations.length === 0 || selected.organizationId.length === 0) {
      return;
    }
    // Set the already selected values
    console.log(`Selected organizations: ${selected}`);
    helper.setValue(selected ?? { organizationId: '', name: '' }, true).then();
  }, [organizations, selected]);

  return (
    <Wrapper>
      <Field
        id={'organization'}
        className="buyer-pro-autocomplete organization-autocomplete"
        {...field}
        component={Autocomplete}
        options={organizations}
        getOptionLabel={(organization: OrganizationDto) => organization.name || ''}
        onChange={(_: SyntheticEvent, newValue: OrganizationDto) => {
          props.setCustomDirtyState();
          return helper.setValue(newValue, false).catch();
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            name={'organization'}
            label={'Organization'}
            placeholder="Optional"
            variant="outlined"
            error={props.helperText}
            helperText={props.helperText ? 'Select an organization' : undefined}
            {...params}
          />
        )}
      />
    </Wrapper>
  );
};

export default SelectOrganization;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
