export const lightTheme = {
  primary: '#5500B4',
  primaryContrast: '#f9f6f0',

  secondary: '#f9f6f0',
  secondaryContrast: '#1E0032',

  background: '#FFFFFF',
  backgroundContrast: '#1E0032',

  lightPurple: '#7878FF',
  lightPurpleContrast: '#f9f6f0',

  text: '#1E0032',

  dealStatusInCreation: '#7878FF',
  dealStatusInCreationContrast: '#f9f6f0',

  dealStatusActive: '#28C88C',
  dealStatusActiveContrast: '#1E0032',

  success: '#28C88C',
  successContrast: '#FFFFFF',

  error: '#FA4655',
  errorContrast: '#FFFFFF',

  warning: '#F5B432',
  warningContrast: '#FFFFFF',

  info: '#0288D1',
  infoContrast: '#FFFFFF',

  scrollbar: '#BEBEBE',
};
export type ColorTheme = typeof lightTheme;
