import styled from '@emotion/styled';
import { getLighterColor } from '../theme/colors/colors';

export const StyledLink = styled.div`
  width: 100%;
  padding: 14px 16px;
  cursor: pointer;
  display: flex;


  &:hover {
    background-color: ${getLighterColor('primary', 14)};
    border-radius: 4px;
  }
`;