import { AutocompleteClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const CustomAutocomplete: Partial<OverridesStyleRules<AutocompleteClassKey>> | undefined = {
  root: {
    '&.buyer-pro-autocomplete': {
      width: '480px',

      '& .MuiAutocomplete-tag': {
        backgroundColor: 'rgba(85, 0, 180, 0.08)', // primary color (#5500B4)
      },

      '&.publishers-autocomplete': {
        width: '100%',
      },
      '&.category-autocomplete': {
        width: '700px',
      },
    },
  },
};
