import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import ToggleButton from '@mui/material/ToggleButton';
import Link from '@mui/material/Link';
import styled from '@emotion/styled';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import { MEDIA_TYPE } from '../../../../../util/deals-util';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { ToggleButtonGroup } from 'formik-mui';
import { AdFormat } from '../../../../../api/services/dto/ad-format';
import { MouseEvent } from 'react';
import { getColor } from '../../../../../theme/colors/colors';
import { FormHelperText } from '@mui/material';

type MediaCardProps = {
  adFormats: AdFormat[];
  onMediaTypeChange: (newMediaType: MEDIA_TYPE) => void;
  setCustomDirtyState: () => void;
  helperText: boolean;
};

const SPONSORED_POST_URL: string =
  'https://www.cwire.com/ad-gallery?cwcreative=849&format=Sponsored';

const MediaCard = ({
  adFormats,
  onMediaTypeChange,
  setCustomDirtyState,
  helperText,
}: MediaCardProps) => {
  const { setFieldValue, validateField } = useFormikContext();

  const handleMediaTypeChange = async (newMediaType: MEDIA_TYPE) => {
    await setFieldValue('adFormats', []);
    validateField('adFormats');
    onMediaTypeChange(newMediaType);
    setCustomDirtyState();
  };

  return (
    <Card elevation={4} sx={{ marginBottom: '32px' }}>
      <CardContent>
        <CardTitle>Media</CardTitle>

        <MediaTypeAndAdFormatsWrapper>
          <>
            <FieldLabel>Media Type</FieldLabel>
            <Field
              component={ToggleButtonGroup}
              exclusive
              size="small"
              name="mediaType"
              type="checkbox"
            >
              <ToggleButton
                value={MEDIA_TYPE.BANNER}
                aria-label="banner"
                onClick={(e: MouseEvent<HTMLElement>, newValue) => handleMediaTypeChange(newValue)}
              >
                Banner
              </ToggleButton>
              <ToggleButton
                value={MEDIA_TYPE.NATIVE}
                aria-label="native"
                onClick={(e: MouseEvent<HTMLElement>, newValue) => handleMediaTypeChange(newValue)}
              >
                Native
              </ToggleButton>
            </Field>
          </>
          <>
            <FieldLabel>Ad Formats</FieldLabel>
            <Field
              component={ToggleButtonGroup}
              name="adFormats"
              type="checkbox"
              className={
                adFormats?.length && adFormats[0].mediaType === MEDIA_TYPE.BANNER
                  ? 'ad-formats-toggle-button-group banner'
                  : 'ad-formats-toggle-button-group'
              }
              onClick={() => setCustomDirtyState()}
            >
              {adFormats.map((adFormat: AdFormat) => {
                return (
                  <ToggleButton
                    disableRipple
                    key={adFormat.id}
                    value={adFormat.id}
                    aria-label={adFormat.name}
                    className="ad-formats-toggle-button"
                  >
                    <IconAndTextWrapper>
                      {adFormat.mediaType === MEDIA_TYPE.BANNER ? (
                        <PhotoOutlinedIcon />
                      ) : (
                        <SpaceDashboardOutlinedIcon />
                      )}
                      <ButtonText>{adFormat.name}</ButtonText>
                    </IconAndTextWrapper>

                    {adFormat.mediaType === MEDIA_TYPE.NATIVE ? (
                      <PreviewLink
                        rel="noopener noreferrer"
                        href={SPONSORED_POST_URL}
                        target="_blank"
                        underline="always"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Preview
                      </PreviewLink>
                    ) : null}
                  </ToggleButton>
                );
              })}
              <FormHelperText error={helperText}>
                {helperText && 'At least one ad format is required'}
              </FormHelperText>
            </Field>
          </>
        </MediaTypeAndAdFormatsWrapper>
      </CardContent>
    </Card>
  );
};

export default MediaCard;

const CardTitle = styled.h3`
  margin-bottom: 8px;
`;

const MediaTypeAndAdFormatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FieldLabel = styled.label`
  margin: 16px 0 8px;
`;

const IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const ButtonText = styled.div`
  font-size: 13px;
`;

const PreviewLink = styled(Link)`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.03em;
  color: ${getColor('primary')};
  position: absolute;
  top: 84px;
  left: 0;
`;
