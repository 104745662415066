import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import React, { useRef, useState } from 'react';
import { Field, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { useAlert } from '../../shared/AlertProvider';
import { warrantService } from '../../../api/services/warrant.service';
import SelectOrganization from '../assign-user-to-organization/cards/SelectOrganization';
import TextField from '@mui/material/TextField';

import { OrganizationDto } from '../../../api/services/dto/user-managment-dto/organization-dto';

export type UserInvitesToOrganization = {
  emails: string;
  organization: OrganizationDto;
};

export const sanitizeEmail = (email: string): string => {
  return email.replace(/\s+/g, '').trim(); // Replace one or more whitespace characters with an empty string
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export enum UserInviteErrorCodes {
  USER_INV_ID_REQUIRED = 'USER_INV_ID_REQUIRED',
  USER_INV_INVALID_EMAIL = 'USER_INV_INVALID_EMAIL',
}

export const getUserInviteErrorMessage = (code: UserInviteErrorCodes) => {
  switch (code) {
    case UserInviteErrorCodes.USER_INV_ID_REQUIRED:
      return 'At least one user email is required!';
    case UserInviteErrorCodes.USER_INV_INVALID_EMAIL:
      return 'Please enter a valid email address and erase any spaces. To add multiple users at once, add their email addresses as a comma-separated list.';
    default:
      return 'Invalid input';
  }
};

export const validate = (
  values: UserInvitesToOrganization
): FormikErrors<UserInvitesToOrganization> => {
  const errors: FormikErrors<UserInvitesToOrganization> = {};
  console.log(`Validate: ${JSON.stringify(values)}`);
  if (!values?.emails) {
    errors.emails = UserInviteErrorCodes.USER_INV_ID_REQUIRED;
  }
  const emails = values?.emails
    ? values.emails.split(',').filter((email) => !isValidEmail(email))
    : [];
  if (Array.isArray(emails) && emails.length > 0) {
    errors.emails = UserInviteErrorCodes.USER_INV_INVALID_EMAIL;
  }

  if (!values.organization?.organizationId) {
    errors.organization = { organizationId: 'Organization is required', name: '' };
  }

  return errors;
};
const InviteUserToOrganizationForm = () => {
  const formikRef = useRef<FormikProps<UserInvitesToOrganization>>(null);
  const { setAlert } = useAlert();
  const [customDirtyState, setDirtyState] = useState<boolean>(false);
  const [initial, setInitial] = useState<UserInvitesToOrganization>({
    emails: '',
    organization: { organizationId: '', name: '' },
  });

  const inviteUsersToOrganization = async (value: UserInvitesToOrganization) => {
    console.log(`Submit: ${JSON.stringify(value)}`);
    console.log(customDirtyState);

    if (value.emails && value.organization) {
      let emails = value.emails.split(',').map(sanitizeEmail);

      await warrantService
        .inviteUsersToOrganization(value.organization?.organizationId, emails)
        .then(() => {
          setAlert({
            open: true,
            title: 'Assigned the user to the organization',
            severity: 'success',
          });
        })
        .catch((reason) => {
          console.error(`Failed to assign user to organization: ${reason}`);
          setAlert({
            open: true,
            title: 'Failed to assign user to organization',
            message: reason.message,
            severity: 'error'
          });
        });
      setInitial({
        emails: '',
        organization: { organizationId: '', name: '' },
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initial}
      onSubmit={inviteUsersToOrganization}
      validate={validate}
      validateOnBlur={true}
      validateOnChange={false}
      validateOnMount={true}
    >
      {({ errors, touched }) => (
        <Form
          onChange={() => {
            setDirtyState(true);
          }}
          id="create-deal-form"
        >
          <FieldsWrapper>
            <FieldTitle>Add user emails</FieldTitle>
            <FieldDescription>
              Add the user's email addresses as a comma-separated list. Don't add any spaces
            </FieldDescription>
            <Field name="emails" id="emails" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="Users"
                  multiline
                  rows={5}
                  error={meta.touched && !!meta.error}
                  helperText={
                    meta.touched && !!meta.error ? getUserInviteErrorMessage(meta.error) : ' '
                  }
                  className="buyer-pro-input wider"
                />
              )}
            </Field>
            <FieldTitle>Select an organization</FieldTitle>
            <SelectOrganization
              selected={initial.organization}
              name="organization"
              id="organization"
              setCustomDirtyState={() => setDirtyState(true)}
              helperText={!!(errors?.organization?.organizationId && touched?.organization)}
            />
          </FieldsWrapper>

          <ButtonContainer>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (!formikRef.current?.isValid) {
                  setAlert({
                    open: true,
                    severity: 'error',
                    title: 'Please complete the form',
                  });
                }
              }}
            >
              Invite User
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default InviteUserToOrganizationForm;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 8px 56px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;
const FieldTitle = styled.h3`
  margin-bottom: 10px;
`;
const FieldDescription = styled.div`
  font-size: small;
  margin-bottom: 10px;
`;
