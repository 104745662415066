import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

type InventoryCardProps = {
  children: ReactNode;
};

const InventoryCard = ({ children }: InventoryCardProps) => {
  return (
    <Card elevation={4} sx={{ marginBottom: '32px' }}>
      <CardContent>
        <CardTitle>Inventory</CardTitle>

        <ComponentsWrapper>
          {/*** Sites component start ***/}
          {children}
          {/*** Sites component end ***/}
        </ComponentsWrapper>
      </CardContent>
    </Card>
  );
};

export default InventoryCard;

const CardTitle = styled.h3`
  margin-bottom: 24px;
`;

const ComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
