import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type DealsTableSearchInputProps = {
  search: string;
  onSearchStringChange: (value: string) => void;
  searchByColumns: string;
  parentComponent: string;
};

const DealsTableSearchInput = ({
  search,
  onSearchStringChange,
  searchByColumns,
  parentComponent,
}: DealsTableSearchInputProps) => {
  return (
    <TextField
      className="buyer-pro-search"
      id={`${parentComponent}-search`}
      placeholder={`Search by ${searchByColumns}`}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disableRipple>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={search}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchStringChange(e.target.value)}
      InputLabelProps={{ shrink: false }}
      variant="outlined"
      margin="normal"
    />
  );
};

export default DealsTableSearchInput;
