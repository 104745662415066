import axios from 'axios';

/**
 * This is bad, fix https://github.com/c-wire/buyer-pro/issues/40 and introduce admin-roles
 * on the users.
 */
export const adminApi = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
    'X-CWIRE-ADMIN' : 'frontend-admin'
  }
});
