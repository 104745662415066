import { ButtonClassKey, IconButtonClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor, getLighterColor } from '../../colors/colors';
import { getVar } from '../../ui-variables/ui-variables';

export const CustomButton: Partial<OverridesStyleRules<ButtonClassKey>> | undefined = {
  root: {
    borderRadius: getVar('borderRadius'),
    '&.light-purple-button':{
      backgroundColor:'rgba(85, 0, 180, 0.08)',
      color: getLighterColor('primary', 40),
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2)',

      '&:hover': {
        backgroundColor: 'rgba(85, 0, 180, 0.10)',
        color: getLighterColor('primary', 35),
      },

      '&:active': {
        backgroundColor: 'rgba(85, 0, 180, 0.10)',
        color: getLighterColor('primary', 35),
      },

      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },

    '&.tertiary-button': {
      backgroundColor: getColor('primaryContrast'),
      color: getColor('primary'),
      border: `1px solid ${getColor('primary')}`,

      '&:hover': {
        backgroundColor: getLighterColor('primary', 60),
        color: getLighterColor('primary', 20),
        border: `1px solid ${getLighterColor('primary', 20)}`,
      },

      '&:active': {
        backgroundColor: getLighterColor('primary', 60),
        color: getLighterColor('primary', 20),
        border: `1px solid ${getLighterColor('primary', 20)}`,
      },

      '&.Mui-disabled': {
        backgroundColor: getColor('primaryContrast'),
        color: 'rgba(0, 0, 0, 0.26)',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
      },
    },

    '&.selection-toggle-button': {
      padding: '4px',
      textTransform: 'lowercase',
      lineHeight: 1,
      minWidth: 'unset',

      '&:hover': {
        backgroundColor: 'rgba(85, 0, 180, 0.08)', // primary color (#5500B4) in rgb
      },
    },

    '&.refresh-button': {
      margin: 'auto',
      backgroundColor: getColor('lightPurple'),
    },
  },
};

export const CustomIconButton: Partial<OverridesStyleRules<IconButtonClassKey>> | undefined = {
  root: {
    backgroundColor: 'transparent',

    '&.buyer-pro-action-button': {
      color: 'rgba(85, 0, 180, 0.56)',
    },
  },
};
