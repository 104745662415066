export enum CURRENCY {
  CHF = 'CHF',
  EUR = 'EUR',
}

export interface BuyerSeatDto {
  id: number;
  name: string;
  tenantId: string;
  buyerSeatId: string;
  ssp: string;
  dsp: string;
  dspId: number;
  createdDate: string;
  modifiedDate: string;
  currency: CURRENCY;
  buyerSeatNameAndId: string; // frontend specific
}
