import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { buyerSeatService } from '../../api/services/buyer-seat.service';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useAlert } from '../shared/AlertProvider';
import { CURRENCY } from '../../api/services/dto/buyer-seat-dto';
import MenuItem from '@mui/material/MenuItem';
import { BuyerSeat } from '../../util/admin-util';
import SelectOrganization from '../organization/assign-user-to-organization/cards/SelectOrganization';
import { BuyerSeatRequestDto } from '../../api/services/dto/buyer-seat-request-dto';

export const validate = (values: BuyerSeat): FormikErrors<BuyerSeat> => {
  const errors: FormikErrors<BuyerSeat> = {};
  console.log(`Validate: ${JSON.stringify(values)}`);
  if (!values.ssp) {
    errors.ssp = 'SSP is required';
  }
  if (!values.name) {
    errors.name = 'Buyer seat name is required';
  }
  if (!values.dsp) {
    errors.dsp = 'DSP (bidder) name is required';
  }
  if (!values.dspId) {
    errors.dspId = 'DSP (bidder) ID is required';
  }
  if (!values.buyerSeatId) {
    errors.buyerSeatId = 'Buyer seat ID (code) is required';
  }
  if (!values.currency) {
    errors.currency = 'Currency is required';
  }
  if (!values.organization?.organizationId) {
    errors.organization = { organizationId: 'Organization is required', name: '' };
  }
  return errors;
};
const BuyerSeatForm = () => {
  const { setAlert } = useAlert();
  const [buyerSeat, setBuyerSeat] = useState<BuyerSeat>({
    ssp: 'Xandr',
    dsp: '',
    dspId: 0,
    buyerSeatId: '',
    name: '',
    organization: { organizationId: '', name: '' },
    currency: CURRENCY.CHF,
  });

  const [_, setDirtyState] = useState<boolean>(false);

  useEffect(() => {
    console.log(buyerSeat);
  }, [buyerSeat]);

  const createBuyerSeat = async (values: BuyerSeat) => {
    const request: BuyerSeatRequestDto = {
      ssp: values.ssp,
      dsp: values.dsp,
      dspId: values.dspId,
      buyerSeatId: values.buyerSeatId,
      name: values.name,
      tenantId: values.organization.organizationId,
      currency: values.currency,
    };

    await buyerSeatService
      .createBuyerSeat(request)
      .then(() => {
        setAlert({ open: true, title: 'Created a buyer seat', severity: 'success' });
      })
      .catch((reason) => {
        console.error(`Failed to create buyer seat: ${reason}`);
        setAlert({ open: true, title: reason.message, severity: 'error' });
      });
    setBuyerSeat(values);
  };

  return (
    <Formik
      initialValues={buyerSeat}
      onSubmit={createBuyerSeat}
      validate={validate}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ errors, touched, isValid }) => (
        <Form
          id="create-buyer-seat-form"
          onChange={() => {
            setDirtyState(true);
          }}
        >
          <FormTitle> Create a Buyer Seat for Tenant(Organization) </FormTitle>

          <FieldsWrapper>
            <Field name="ssp" id="ssp" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="SSP"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && !!meta.error ? 'SSP is required' : ' '}
                  className="buyer-pro-input"
                />
              )}
            </Field>
            <Field name="dsp" id="dsp" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="DSP (bidder_name)"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && !!meta.error ? 'DSP (bidder_name) is required' : ' '}
                  className="buyer-pro-input"
                />
              )}
            </Field>
            <Field name="dspId" id="dspId" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="DSP ID (bidder_id)"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && !!meta.error ? 'DSP ID (bidder_id) is required' : ' '}
                  className="buyer-pro-input"
                />
              )}
            </Field>
            <Field name="buyerSeatId" id="buyerSeatId" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="Buyer Seat ID (code)"
                  error={meta.touched && !!meta.error}
                  helperText={
                    meta.touched && !!meta.error ? 'Buyer Seat ID (code) is required' : ' '
                  }
                  className="buyer-pro-input"
                />
              )}
            </Field>
            <Field name="name" id="name" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="Buyer Seat Name (name)"
                  error={meta.touched && !!meta.error}
                  helperText={
                    meta.touched && !!meta.error ? 'Buyer Seat Name (name) is required' : ' '
                  }
                  className="buyer-pro-input"
                />
              )}
            </Field>
            <Field name="currency" id="currency" type="text">
              {({ field, meta }: any) => (
                <TextField
                  {...field}
                  label="Currency"
                  error={!!meta.touched && !!meta.error}
                  helperText={!!meta.touched && !!meta.error ? 'Currency is required' : ' '}
                  id="currency-select"
                  className="buyer-pro-select"
                  select
                  variant="outlined"
                >
                  <MenuItem key={CURRENCY.CHF} value={CURRENCY.CHF}>
                    {CURRENCY.CHF}
                  </MenuItem>
                  <MenuItem key={CURRENCY.EUR} value={CURRENCY.EUR}>
                    {CURRENCY.EUR}
                  </MenuItem>
                </TextField>
              )}
            </Field>
            <SelectOrganization
              selected={buyerSeat.organization}
              name="organization"
              id="organization"
              setCustomDirtyState={() => setDirtyState(true)}
              helperText={!!(errors?.organization?.organizationId && touched?.organization)}
            />
          </FieldsWrapper>

          <ButtonContainer>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (!isValid) {
                  setAlert({
                    open: true,
                    severity: 'error',
                    title: 'Please complete the form',
                  });
                }
              }}
            >
              Create
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default BuyerSeatForm;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 8px 56px;
`;

const FormTitle = styled.h2`
  margin-bottom: 30px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
