import applicationApi from '../api';

export interface TaxonomyCategory {
  id: string; // yes, string
  name: string;
}

export const IabTaxonomyService = {
  fetchAudience: (): Promise<TaxonomyCategory[]> => {
    return applicationApi.get('/iab/audience').then((res) => res.data);
  },

  fetchSensitiveContent: (): Promise<TaxonomyCategory[]> => {
    return applicationApi.get('/iab/sensitive-content').then((res) => res.data);
  },
};
