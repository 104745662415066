import React, {useEffect} from 'react';
import Alert from '@mui/material/Alert';
import {Action, AlertProps, useAlert} from './AlertProvider';
import Button from "@mui/material/Button";
import {AlertTitle} from "@mui/material";

const AlertComponent = () => {
  const {alert, setAlert} = useAlert();

  useEffect(() => {
    if (alert.message) {
      console.error(`Error occurred: ${alert.message}`)
    }
  }, [alert.message]);

  const closeAlert = () => setAlert((prevState) => ({
    ...prevState,
    open: false,
  }))

  if (["info", "success"].includes(alert.severity)) {
    return <FadingAlert alert={alert} closeAlert={closeAlert}></FadingAlert>
  } else {
    return <ActionableAlert alert={alert} closeAlert={closeAlert}></ActionableAlert>
  }
}

export default AlertComponent;


/**
 * The alert has no actionable and no close button. It fades out after 5 seconds.
 */
const FadingAlert  = (props: { alert: AlertProps, closeAlert: () => void }) => {
  useEffect(() => {
    if (props.alert.open) {
      const timer = setTimeout(props.closeAlert, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [props.alert.open])

  return (
    <div style={{top: 70, right: 6, position: 'fixed'}}>
      {props.alert.open && (
        <Alert
          elevation={6}
          variant="filled"
          severity={props.alert.severity}
          style={{width: 400 + 'px'}}
        >
          <AlertTitle>
            {props.alert.title}
          </AlertTitle>
          {props.alert.message}
        </Alert>
      )}
    </div>
  );
}

/**
 * The alert has one actionable Button, that closes the alert. If it fails it will display a "terminal" error with the
 * message to contact support.
 */
const ActionableAlert = (props: { alert: AlertProps, closeAlert: () => void }) => {

  return (
    <div style={{top: 70, right: 6, position: 'fixed'}}>
      {props.alert.open && (
        <Alert
          elevation={6}
          variant="filled"
          severity={props.alert.severity}
          style={{width: 400 + 'px'}}
          action={
            <ActionButton action={props.alert.action} closeAlert={props.closeAlert}/>
          }
        >
          <AlertTitle>
            {props.alert.title}
          </AlertTitle>
          {props.alert.message}
        </Alert>
      )}
    </div>
  );
}

const ActionButton = (props: { action?: Action, closeAlert: () => void }) => {
  const {setAlert} = useAlert();

  if (props.action) {
    return (
      <Button color="inherit" size="small" onClick={() => {
        props.action?.function()
          .catch(reason => setAlert({
            open: true,
            severity: 'error',
            title: `${props.action?.label} failed: ${reason.message}`,
            message: `Please contact buyer@cwire.com`,
          }))

        props.closeAlert()

      }}>{props.action.label}</Button>
    )
  }
  return (
    <Button color="inherit" size="small" onClick={props.closeAlert}>CLOSE</Button>
  )
};