import styled from '@emotion/styled';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import NameCard from './cards/NameCard';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SharedState, SubmitStep, UpdateState } from '../Create';
import { SegmentService } from '../../../../api/services/segment.service';
import { IncludesCard } from './cards/IncludesCard';
import { ExcludesCard } from './cards/ExcludesCard';
import { TaxonomyCategory } from '../../../../api/services/iab.service';
import Keywords from './cards/Keywords';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAlert } from '../../../shared/AlertProvider';

export type InterestValues = {
  categories: TaxonomyCategory[];
  keywords: string[];
};
export type SensitiveTopicsValues = {
  categories: TaxonomyCategory[];
  keywords: string[];
};

export type SegmentValues = {
  dealId: number;
  segmentId?: number;
  name: string;
  includes: InterestValues;
  excludes: SensitiveTopicsValues;
};

export const validate = (values: SegmentValues): FormikErrors<SegmentValues> => {
  const errors: FormikErrors<SegmentValues> = {};
  console.log(`Validate: ${JSON.stringify(values)}`);

  if (!values.name) {
    errors.name = 'Segment name is required';
  }

  return errors;
};

const CreateSegment = forwardRef<SubmitStep, SharedState & UpdateState>((props, ref) => {
  const formikRef = useRef<FormikProps<SegmentValues>>(null);
  const { setAlert } = useAlert();
  const [customDirtyState, setDirtyState] = useState<boolean>(false);

  const [includeCategories, setIncludeCategories] = useState<string[]>([]);
  const [excludeCategories, setExcludeCategories] = useState<string[]>([]);
  const [segmentId, setSegmentId] = useState<number | undefined>(props.segmentId);
  const [initial, setInitial] = useState<SegmentValues>({
    dealId: props.dealId!,
    segmentId: props.segmentId,
    name: '',
    includes: {
      categories: [],
      keywords: [],
    },
    excludes: {
      categories: [],
      keywords: [],
    },
  });

  useEffect(() => {
    if (props.segmentId !== undefined) {
      SegmentService.get(props.segmentId)
        .then((segment) => {
          // Set initial state with segment data
          setInitial({
            dealId: props.dealId!,
            name: segment.name,
            includes: {
              categories: [], // yes, empty array because of bogus AutoComplete component
              keywords: segment.includes.keywords,
            },
            excludes: {
              categories: [],
              keywords: segment.excludes.keywords,
            },
          });

          setIncludeCategories(segment.includes.categories || []);
          setExcludeCategories(segment.excludes.categories || []);
        })
        .catch((reason) =>
          setAlert({
            open: true,
            severity: 'error',
            title: 'Failed to fetch segment',
            message: reason.message,
          })
        );
    }
  }, []);

  useEffect(() => {
    console.log(includeCategories);
    console.log(excludeCategories);
  }, [includeCategories, excludeCategories]);

  const submit = (values: SegmentValues): Promise<void> => {
    console.log(`Submit: ${JSON.stringify(values)}`);
    console.log(customDirtyState);
    if (!segmentId) {
      return SegmentService.create({
        dealId: props.dealId!,
        name: values.name,
        includes: {
          categories: values.includes.categories.map((cat) => cat.id),
          keywords: values.includes.keywords,
        },
        excludes: {
          categories: values.excludes!.categories.map((cat) => cat.id),
          keywords: values.excludes.keywords,
        },
      })
        .then((segment) =>
          props.updateState({
            dealId: props.dealId,
            segmentId: segment.id,
          })
        )
        .catch((reason) =>
          setAlert({
            open: true,
            severity: 'error',
            title: 'Failed to create a segment',
            message: reason.message,
          })
        );
    } else if (customDirtyState) {
      return SegmentService.update(segmentId, {
        name: values.name,
        includes: {
          categories: values.includes.categories.map((cat) => cat.id),
          keywords: values.includes.keywords,
        },
        excludes: {
          categories: values.excludes!.categories.map((cat) => cat.id),
          keywords: values.excludes.keywords,
        },
      })
        .then((segment) =>
          props.updateState({
            dealId: props.dealId,
            segmentId: segment.id,
          })
        )
        .catch((reason) =>
          setAlert({
            open: true,
            severity: 'error',
            title: 'Failed to update a segment',
            message: reason.message,
          })
        );
    } else {
      return Promise.resolve();
    }
  };

  let submitFormRef: SubmitStep = (): Promise<void> => {
    console.debug(`Submitting segment form..`);
    let formikProps = formikRef.current;
    if (formikProps) {
      if (formikProps.isValid) return formikProps.submitForm();
      else {
        // This ensures we don't submit an empty form.
        // Note: This only triggers (isValid == false) when validateOnMount={true} is set further down.
        formikProps?.setFieldTouched('name', true, true);
        return Promise.reject(new Error('Please complete the form'));
      }
    }
    return Promise.reject(new Error('Something went wrong!', { cause: 'Could not reference access formik form properties.' }));
  };

  useImperativeHandle(ref, () => submitFormRef);
  return (
    <StepWrapper>
      <StepTitle>Add contextual targeting</StepTitle>
      <Formik
        innerRef={formikRef}
        initialValues={initial}
        enableReinitialize={true}
        onSubmit={submit}
        validate={validate}
        validateOnBlur={true}
        validateOnMount={true}
      >
        {({ errors, touched, isValidating }) => (
          <Form
            onChange={() => {
              setDirtyState(true);
            }}
            id="create-deal-form"
          >
            <input name="dealId" id="dealId" hidden={true} value={props.dealId} />
            <input name="segmentId" id="segmentId" hidden={true} value={segmentId || undefined} />

            <NameCard name="name" id="name" />
            <Card elevation={4} sx={{ marginBottom: '32px' }}>
              <CardContent>
                <CardTitle>Inclusion</CardTitle>
                <IncludesCard
                  selected={includeCategories}
                  name="includes.categories"
                  id="includes.categories"
                  setCustomDirtyState={() => setDirtyState(true)}
                />
                <InCardConjunctions>
                  OR
                </InCardConjunctions>
                <Keywords
                  setCustomDirtyState={() => setDirtyState(true)}
                  name="includes.keywords"
                  id="includes.keywords"
                />
              </CardContent>
            </Card>
            <Conjunctions>
              AND
            </Conjunctions>
            <Card elevation={4} sx={{ marginBottom: '32px' }}>
              <CardContent>
                <CardTitle>Exclusion</CardTitle>
                <ExcludesCard
                  selected={excludeCategories}
                  name="excludes.categories"
                  id="excludes.categories"
                  setCustomDirtyState={() => setDirtyState(true)}
                />
                <InCardConjunctionsAnd style={{paddingRight:-20}}>
                  AND
                </InCardConjunctionsAnd>
                <Keywords
                  setCustomDirtyState={() => setDirtyState(true)}
                  name="excludes.keywords"
                  id="excludes.keywords"
                />
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </StepWrapper>
  );
});

export default CreateSegment;

const StepWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 32px 8px 16px;
  line-height: 30px;
`;

const StepTitle = styled.h2`
  margin-bottom: 32px;
`;

const CardTitle = styled.h3`
  margin-bottom: 16px;
`;

const Conjunctions = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin: 10px;
  width:725px;
  text-align: center;
  margin: 0 0 20px;
`;

const InCardConjunctions = styled.div`
  color: rgba(0, 0, 0, 0.5);
  width:675px;
  text-align: center;
  margin: 20px 0 20px;
`;

const InCardConjunctionsAnd = styled.div`
  color: rgba(0, 0, 0, 0.5);
  width:675px;
  text-align: center;
  margin: 20px 0 20px;
`;
