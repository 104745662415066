import styled from '@emotion/styled';
import { getColor } from '../../../../../theme/colors/colors';

const SitesWrapper = styled.div`
  width: 800px;
  height: 560px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  margin-bottom: 24px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 64px;
  padding: 0 16px;

  &:last-of-type {
    flex-grow: 1;
    border-bottom: none;
    padding: 16px;
    align-items: flex-start;
  }
`;

const FirstRow = styled.div`
  height: 72px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const SiteListAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const SelectionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`;

const SelectedText = styled.div`
  padding: 6px 4px 4px;
`;

const Pipe = styled.div`
  color: ${getColor('primary')};
  margin: 0 4px;
  font-size: 18px;
`;

const SiteList = styled.div`
  height: 100%;
  overflow-y: auto;
  font-size: 13px;
`;

const SiteRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(30, 0, 50, 0.12); // text color (#1E0032)
  padding: 12px 8px 2px;
  cursor: pointer;

  svg {
    font-size: 16px;
    fill: rgba(85, 0, 180, 0.56); // primary color (#5500B4)
    
  }
  svg[data-testid=CheckCircleIcon]{
    font-size: 16px;
    fill: rgba(83, 211, 163, 1)!important;
    
  }
`;

const SelectedSiteRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid rgba(30, 0, 50, 0.12); // text color (#1E0032)
  padding: 8px 8px 2px;

  span {
    height: 20px;
  }

  svg {
    font-size: 16px;
    fill: rgba(83, 211, 163, 1); // primary color (#5500B4)
  }
`;

const NoMatches = styled.div`
  padding: 12px 8px 2px;
`;

export const StyledSiteSelection = {
  SitesWrapper,
  Column,
  Row,
  FirstRow,
  SiteListAndButtonWrapper,
  SelectionButtonsWrapper,
  ClearButtonWrapper,
  SelectedText,
  Pipe,
  SiteList,
  SiteRow,
  SelectedSiteRow,
  NoMatches,
};
