import { SyntheticEvent, useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { FieldHookConfig } from 'formik/dist/Field';
import { UserDto } from '../../../../api/services/dto/user-managment-dto/user-dto';
import { warrantService } from '../../../../api/services/warrant.service';


export const SelectUser = (
  props: { selected: UserDto; setCustomDirtyState: () => void, helperText: boolean } & FieldHookConfig<
    UserDto
  >,
) => {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [selected, setSelected] = useState<UserDto>(props.selected);
  const [field, _, helper] = useField(props);

  // Update state when props change. TODO: Can this be done better?
  useEffect(() => {
    warrantService.fetchUsers().then(users => {
      setUsers(users);
    });
    setSelected(props.selected);
  }, [props]);

  /**
   * Used for setting the selected values from props
   * This would not be needed it the AutoComplete component would be using the ids instead of objects:
   * https://github.com/mui/material-ui/issues/23708
   * https://github.com/mui/material-ui/issues/37287
   */
  useEffect(() => {
    // reactive dependencies ftw.
    if (users.length === 0 || selected.userId.length === 0) {
      return;
    }
    // Set the already selected values
    console.log(`Selected users: ${selected}`);
    helper
      .setValue(
        selected ?? { userId: '', email: '' },
        true,
      )
      .then();
  }, [users, selected]);

  return (
    <Wrapper>
      <Field
        id={'user'}

        className='buyer-pro-autocomplete user-autocomplete'
        {...field}
        component={Autocomplete}
        options={users}
        getOptionLabel={(user: UserDto) => user.email || ''}
        onChange={(_: SyntheticEvent, newValue: UserDto) => {
          props.setCustomDirtyState();
          return helper.setValue(newValue, false).catch();
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (

          <TextField
            name={'user'}

            label={'Select a user'}
            placeholder='Optional'
            variant='outlined'
            error={props.helperText}
            helperText={props.helperText ? 'Select a user' : undefined}
            {...params}
          />
        )}
      />
    </Wrapper>
  );
};

export default SelectUser;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 24px;
`;
